/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_contact {
  max-width: 840px;
  font-weight: 300;
  color: #333333;
  padding: 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-size: 15px;
}
.templates_project_gallery_contact .title {
  color: #333333;
  font-size: 30px;
}
.templates_project_gallery_contact .content {
  padding: 20px 0 30px 0;
}
.templates_project_gallery_contact .content .phone {
  margin-bottom: 5px;
}
.templates_project_gallery_contact .content .contactForm {
  margin-bottom: 5px;
}
.templates_project_gallery_contact .content .contactForm:hover {
  cursor: pointer;
  text-decoration: underline;
}
.templates_project_gallery_contact .content a {
  color: #333333;
  text-decoration: none;
}
.templates_project_gallery_contact .content a:hover {
  text-decoration: underline;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_project_gallery_contact {
  min-height: unset !important;
}
body.mobile .templates_project_gallery_contact {
  font-size: 10px;
  padding: 18px !important;
  width: 100%;
}
body.mobile .templates_project_gallery_contact .title {
  font-size: 20px;
}
body.mobile .templates_project_gallery_contact .content {
  padding: 10px 0;
}
