/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.circle {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  z-index: 20;
}
.templates_circles_1tl-3irt_1tl-3irt .texts {
  position: absolute;
  width: 32%;
  padding: 0;
  bottom: 15%;
  left: 6%;
}
.templates_circles_1tl-3irt_1tl-3irt .texts h2 {
  font-size: 6.1vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_circles_1tl-3irt_1tl-3irt .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_circles_1tl-3irt_1tl-3irt .images {
  position: absolute;
  bottom: 15%;
  right: 6%;
  top: initial;
  left: initial;
  width: 49%;
  height: 85%;
}
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c1 {
  top: 25vh;
  left: 0;
}
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c1 div {
  position: absolute;
  top: 16vw;
  width: 15vw;
  text-align: center;
  font-size: 1.3vw;
}
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c2 {
  top: 25vh;
  left: 18vw;
}
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c3 {
  top: 25vh;
  right: -2vw;
}
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c1 div,
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c2 div,
.templates_circles_1tl-3irt_1tl-3irt .images .circle.c3 div {
  position: absolute;
  top: 16vw;
  width: 15vw;
  text-align: center;
  font-size: 2vh;
}
body.breakPoint-l .templates_circles_1tl-3irt_1tl-3irt .texts h2 {
  font-size: 5vh;
}
