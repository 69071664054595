/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
* {
  touch-action: none;
  -ms-touch-action: none;
}
.layouts_gridNavigator_gridNavigator section.head {
  position: absolute;
  width: 100%;
  height: 120px;
}
.layouts_gridNavigator_gridNavigator section.head > * {
  padding: 20px;
}
.layouts_gridNavigator_gridNavigator section.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.layouts_gridNavigator_gridNavigator section.fullScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
}
.layouts_gridNavigator_gridNavigator section.fullScreen.isShown {
  display: block;
}
.layouts_gridNavigator_gridNavigator.columnMenu section.head {
  background-color: #FFFFFF;
}
.layouts_gridNavigator_gridNavigator.columnMenu section.grid {
  height: calc(100% - 120px);
  margin-top: 120px;
}
.layouts_gridNavigator_gridNavigator.backButton section.head {
  background-color: #FFFFFF;
}
.layouts_gridNavigator_gridNavigator.backButton section.grid {
  height: calc(100% - 120px);
  width: calc(100vw - 121px);
  margin-top: 120px;
  margin-left: 121px;
}
.layouts_gridNavigator_gridNavigator.coloredBackground section.head {
  background-color: unset;
}
.layouts_gridNavigator_gridNavigator.coloredBackground section.grid {
  height: calc(100% - 120px);
  margin-top: 120px;
}
.layouts_gridNavigator_gridNavigator.fullHeight section.grid {
  height: 100%;
  margin-top: 0;
}
@media print {
  .layouts_gridNavigator_gridNavigator section.grid {
    height: calc(100% - 120px) !important;
    width: 100% !important;
  }
}
.layouts_gridNavigator_gridNavigator.endlessScroll,
.layouts_gridNavigator_gridNavigator.endlessScroll * {
  touch-action: auto;
  -ms-touch-action: auto;
}
body.mobile .layouts_gridNavigator_gridNavigator section.head {
  height: 76px;
}
body.mobile .layouts_gridNavigator_gridNavigator section.head > * {
  padding: 13px;
}
body.mobile .layouts_gridNavigator_gridNavigator.columnMenu section.grid {
  height: calc(100% - 76px);
  margin-top: 76px;
}
body.mobile .layouts_gridNavigator_gridNavigator.backButton section.grid {
  height: calc(100% - 76px);
  width: calc(100vw - 77px);
  margin-top: 76px;
  margin-left: 77px;
}
body.mobile .layouts_gridNavigator_gridNavigator.coloredBackground section.grid {
  height: calc(100% - 76px);
  overflow: hidden;
  margin-top: 76px;
}
