/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_investmentProperties_object {
  float: left;
  clear: left;
  border: 1px solid #F7F7F7;
  padding: 2vh;
  margin-left: 4vh;
  margin-top: 3vh;
  width: calc(50% - 6vh);
  height: calc(100% - 6vh);
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 33px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 33px rgba(0, 0, 0, 0.2);
  margin-bottom: 3vh;
  box-sizing: border-box;
}
.templates_investmentProperties_object:nth-child(even) {
  left: calc(50% + 1vh);
}
.templates_investmentProperties_object .image {
  position: relative;
  border: 1px solid #E5E5E5;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: calc(100% - 2px);
  height: calc(50% - 2px);
}
.templates_investmentProperties_object .content {
  position: relative;
  height: 100%;
}
.templates_investmentProperties_object .content .title {
  position: relative;
  border: 1px solid #E5E5E5;
  border-top: 0;
  width: calc(100% - 2px - 3vh);
  height: calc(15% - 1px);
  line-height: 12vh;
  padding-left: 3vh;
  font-size: 3.2vh;
}
.templates_investmentProperties_object .content .description {
  position: relative;
  border: 1px solid #E5E5E5;
  border-top: 0;
  width: calc(100% - 2px - 3vh);
  height: calc(13% - 1px);
  line-height: 180%;
  padding-left: 3vh;
  padding-top: 1.5vh;
  font-size: 1.7vh;
}
.templates_investmentProperties_object .content .description p {
  margin: 0;
}
.templates_investmentProperties_object .content .info {
  position: relative;
  border: 1px solid #E5E5E5;
  border-top: 0;
  width: calc(100% - 2px);
  height: calc(20% - 1px);
}
.templates_investmentProperties_object .content .info .address {
  border-right: 1px solid #E5E5E5;
  width: calc(35% - 1px);
  height: 100%;
  float: left;
}
.templates_investmentProperties_object .content .info .address p {
  background: url(/images/address-icon.jpg);
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 1.7vh;
  margin: 3vh 0 0 3vh;
  padding: 0 0 0 30px;
  line-height: 140%;
}
.templates_investmentProperties_object .content .info .details {
  width: 65%;
  height: 100%;
  float: left;
}
.templates_investmentProperties_object .content .info .details table {
  margin-left: 3vh;
  margin-top: 1.2vh;
  width: calc(100% - 6vh);
}
.templates_investmentProperties_object .content .info .details table td {
  font-size: 1.7vh;
  width: 50%;
  line-height: 120%;
}
.templates_investmentProperties_object .content .info .details table td:nth-child(even) {
  text-align: right;
}
body.breakPoint-l .templates_investmentProperties_object .info .address p {
  background: none;
  padding: 0;
}
