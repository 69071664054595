/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.base_modal_modal.container_errors_errors {
  z-index: 900000;
}
.base_modal_modal.container_errors_errors .background {
  background-color: #000000;
  opacity: 0.8;
}
.base_modal_modal.container_errors_errors .window {
  width: 70%;
  max-width: 770px;
  margin: 10% auto 0 auto;
  position: relative;
  background: none;
  border: none;
  left: auto;
  top: auto;
  height: auto;
}
.base_modal_modal.container_errors_errors .window * {
  border: none;
  background: none;
  color: #FFFFFF;
}
.base_modal_modal.container_errors_errors .window section.body {
  height: auto;
  overflow: initial;
}
.base_modal_modal.container_errors_errors .window section.header {
  color: #FFFFFF;
  display: none;
  background: none;
  border: none;
}
.base_modal_modal.container_errors_errors .window section.footer {
  height: auto;
  border: none;
}
.base_modal_modal.container_errors_errors .window section.footer .buttons {
  height: auto;
  text-align: center;
}
.base_modal_modal.container_errors_errors .window section.footer .buttons button {
  float: none;
  background-color: #006184;
  border: 2px solid #006184;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 18px;
}
.base_modal_modal.container_errors_errors .window section.footer .buttons button:hover {
  background: none;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
div.errors_errors {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 1vh;
}
div.errors_errors > * {
  float: left;
}
div.errors_errors .image {
  height: 100%;
  width: 40%;
  margin-right: 4vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  display: none;
}
div.errors_errors .content {
  width: 100%;
}
div.errors_errors .content h1 {
  font-family: OpenSans, Arial, sans-serif;
  font-size: 64px;
  line-height: 1.3;
  margin: 0 0 1vh 0;
  text-align: center;
}
div.errors_errors .content h2 {
  display: none;
}
div.errors_errors .content p {
  font-family: OpenSans, Arial, sans-serif;
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  line-height: 1.6;
}
body.error_container {
  background-color: #333333;
}
body.error_container > .errors_errors {
  width: 70%;
  max-width: 770px;
  margin: 0 auto;
  padding-top: 10%;
  position: relative;
  background: none;
  border: none;
  left: auto;
  top: auto;
  height: auto;
  float: none;
}
body.error_container > .errors_errors .image {
  display: none;
  float: none;
}
body.error_container > .errors_errors .content {
  width: 100%;
}
body.error_container > .errors_errors .content h1 {
  font-family: OpenSans, Arial, sans-serif;
  font-size: 64px;
  line-height: 1.3;
  margin: 0 0 1vh 0;
  color: #FFFFFF;
  text-align: center;
}
body.error_container > .errors_errors .content h2 {
  display: none;
}
body.error_container > .errors_errors .content p {
  font-family: OpenSans, Arial, sans-serif;
  font-size: 32px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 600;
  line-height: 1.6;
}
body.error_container > .errors_errors .content a {
  text-decoration: none;
  float: none;
  background-color: #006184;
  border: 2px solid #006184;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 18px;
}
body.error_container > .errors_errors .content a:hover {
  background: none;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
@media screen and (max-width: 1024px) {
  div.errors_errors .content h1 {
    font-size: 38px;
  }
  div.errors_errors .content p {
    font-size: 24px;
  }
}
