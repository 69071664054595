/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_standard_tl-gr_tl-gr .texts {
  position: absolute;
  width: 32%;
  padding: 0;
  bottom: 15%;
  left: 6%;
}
.templates_standard_tl-gr_tl-gr .texts h3 {
  font-size: 5.6vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_standard_tl-gr_tl-gr .texts p {
  font-size: 2vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_standard_tl-gr_tl-gr .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_standard_tl-gr_tl-gr .images {
  position: absolute;
  right: 2vh;
  width: 55vw;
  height: 86vh;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-wrapper-outer {
  height: 80vh;
  width: calc(55vw - 3vh);
  margin: 3vh 3vh 3vh 0;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-wrapper-outer .owl-wrapper {
  height: 100%;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-wrapper-outer .owl-wrapper .owl-item {
  height: 100%;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .item .title {
  width: 100%;
  height: 4vh;
  background-color: #EEEEEE;
  color: #B9B9B9;
  text-align: center;
  font-size: 2vh;
  line-height: 3.8vh;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-wrapper-outer .owl-wrapper .owl-item .item .img {
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100% - 4vh);
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-controls {
  margin-top: -7vh;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-controls .owl-page.active span,
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: alpha(opacity=90);
  opacity: 0.9;
  background: #FFF;
}
.templates_standard_tl-gr_tl-gr .images.owl-theme .owl-controls .owl-page span {
  filter: alpha(opacity=90);
  opacity: 0.9;
  background: #999;
}
body.breakPoint-l .templates_standard_tl-gr_tl-gr .texts h3 {
  font-size: 5vh;
}
