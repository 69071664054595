/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_objectList_objectList {
  max-width: 840px;
  width: calc(100% - 210px);
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #FFFFFF;
}
.templates_project_objectList_objectList h2 {
  color: #333333;
  font-size: 30px;
  line-height: 100%;
  font-weight: 300;
  margin: 0;
  padding: 40px 0 40px 40px;
}
.templates_project_objectList_objectList .filter h4 {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}
.templates_project_objectList_objectList .list table {
  width: 100%;
  border-spacing: 0;
}
.templates_project_objectList_objectList .list table tbody tr.hidden {
  display: none;
}
.templates_project_objectList_objectList .list table tbody tr.odd {
  background-color: #edf0f1;
}
.templates_project_objectList_objectList .list table tbody tr td {
  padding: 0.75rem;
}
.templates_project_objectList_objectList .list table tbody tr td:last-of-type {
  text-align: right;
}
.templates_project_objectList_objectList .list table tbody tr td:hover {
  cursor: pointer;
}
.templates_project_objectList_objectList .list table tbody tr:hover td {
  text-decoration: underline;
}
.templates_project_objectList_objectList.emptyObjectList {
  display: none;
}
body.mobile .templates_project_objectList_objectList {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}
body.mobile .templates_project_objectList_objectList h2 {
  font-size: 20px;
  padding: 5px 10px 25px 10px;
}
body.mobile .templates_project_objectList_objectList .filter h4 {
  display: none;
}
body.mobile .templates_project_objectList_objectList .filter select {
  width: 100%;
}
body.mobile .templates_project_objectList_objectList .list table {
  font-size: 14px;
}
body.mobile .templates_project_objectList_objectList .list table tbody tr td {
  padding: 10px;
}
body.mobile .templates_project_objectList_objectList .list table tbody tr td .additionalInfo {
  display: none;
}
