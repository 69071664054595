/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
* {
  font-family: OpenSans, Arial, sans-serif;
}
.environmentMeasurement {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: 0;
  display: none;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body.desktop .mobile {
  display: none;
}
body.desktop .desktop {
  display: block;
}
body.mobile .mobile {
  display: block;
}
body.mobile .desktop {
  display: none;
}
div.emptyTemplate {
  display: none !important;
}
