/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_standard_html_html {
  font-weight: 300;
  box-sizing: border-box;
}
.templates_standard_html_html .texts {
  clear: left;
  width: 100%;
  box-sizing: border-box;
}
.templates_standard_html_html .texts .title {
  position: relative;
}
.templates_standard_html_html .texts .text {
  clear: left;
  width: 100%;
  margin: 0;
}
.templates_standard_html_html .texts .text ul {
  margin: 0;
  padding: 0 0 0 1.25em;
  list-style-type: square;
}
.templates_standard_html_html .texts .text ul li {
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_standard_html_html .texts .text p {
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: 170%;
  margin: 0;
}
.templates_standard_html_html .texts .text p strong {
  font-weight: bold;
}
.templates_standard_html_html .texts .text a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_standard_html_html .texts .text > *:last-child {
  margin-bottom: 0;
}
.templates_standard_html_html .texts .title h2,
.templates_standard_html_html .texts .text h3,
.templates_standard_html_html .texts .text p strong,
.templates_standard_html_html .texts .title h2 *,
.templates_standard_html_html .texts .text h3 *,
.templates_standard_html_html .texts .text p strong * {
  font-size: 30px;
  color: #333333;
  font-weight: 300 !important;
  margin: 0 0 30px 0;
  line-height: 100%;
}
.templates_standard_html_html.collapsable:not(.open) {
  height: 5vh !important;
}
.layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html {
  min-height: unset !important;
  max-width: 840px;
  width: calc(100% - 210px);
}
.layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts {
  height: unset;
  margin: 0;
  padding: 0;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .templates_standard_html_html {
  background-color: #FFFFFF;
  padding: 40px 60px;
}
body.mobile .templates_standard_html_html {
  width: 100%;
}
body.mobile .templates_standard_html_html .texts {
  padding: 0;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html {
  padding: 12px 20px;
  width: 100%;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .title {
  margin: 5px 0 10px 0;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .title h2,
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text h3,
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text p strong,
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .title h2 *,
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text h3 *,
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text p strong * {
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text ul {
  list-style-type: square;
  padding: 0 0 0 1.25em;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text ul li {
  font-size: 12px;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_html_html .texts .text p {
  font-size: 12px;
}
