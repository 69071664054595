/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_keyData {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-weight: 300;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #333333;
}
.templates_project_gallery_keyData.isHidden {
  display: none;
}
.templates_project_gallery_keyData h2,
.templates_project_gallery_keyData h3 {
  font-size: 30px;
  color: #333333;
  line-height: 120%;
  font-weight: 300;
  margin: 0;
}
.templates_project_gallery_keyData table {
  font-size: inherit;
  font-weight: 300;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}
body.mobile .templates_project_gallery_keyData {
  font-size: 10px;
  padding: 18px !important;
  flex: 1 1 auto;
}
body.mobile .templates_project_gallery_keyData h2,
body.mobile .templates_project_gallery_keyData h3 {
  font-size: 20px;
}
body.mobile .templates_project_gallery_keyData .content {
  padding: 10px 0;
}
