/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_team_member {
  float: left;
  width: calc(20% - 1px);
  height: calc(50% - 1px);
  background-color: #F7F8FA;
  margin: 1px 0 0 1px;
  text-align: center;
}
.templates_team_member img {
  margin: 10%;
  width: 53%;
  height: auto;
  border-radius: 50%;
}
.templates_team_member p {
  color: #333333;
  margin: 0;
  line-height: 130%;
}
.templates_team_member p.name {
  font-size: 1.4vw;
}
.templates_team_member p.job,
.templates_team_member p.phone,
.templates_team_member p.fax {
  font-size: 1vw;
  margin-bottom: 2vh;
}
.templates_team_member p.phone,
.templates_team_member p.fax {
  display: none;
}
.templates_team_member p a {
  font-weight: bold;
  font-size: 1vw;
  color: #006184;
  text-decoration: none;
}
body.mobile .templates_team_member {
  width: calc(50% - 1px);
}
body.mobile .templates_team_member img {
  padding: 8%;
  width: 50%;
  height: auto;
}
body.mobile .templates_team_member p.name {
  font-size: 3.6vw;
}
body.mobile .templates_team_member p.job {
  font-size: 3vw;
  margin-bottom: 2vh;
}
body.mobile .templates_team_member p.phone,
body.mobile .templates_team_member p.fax {
  font-size: 1.8vh;
}
body.mobile .templates_team_member p a {
  font-size: 3vw;
}
body.touchable .templates_team_member {
  opacity: 1;
}
