/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_news_list_teaser {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: none;
  clear: both;
  cursor: pointer;
  padding: 20px;
  margin-bottom: 12px;
  background-color: #FFFFFF;
  color: #333333;
  box-sizing: border-box;
}
.templates_news_list_teaser .image {
  width: 280px;
  height: 200px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex: 1 0 280px;
}
.templates_news_list_teaser .data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 0 12px 20px;
  box-sizing: border-box;
  width: calc(100% - 280px);
}
.templates_news_list_teaser .data .title {
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
  line-height: 150%;
}
.templates_news_list_teaser .data .title:hover {
  text-decoration: underline;
}
.templates_news_list_teaser .data .desc {
  font-size: 12px;
  margin-top: 10px;
  height: 84px;
  line-height: 175%;
  overflow: hidden;
}
.templates_news_list_teaser .data .info {
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  margin-top: auto;
  color: #999999;
}
.templates_news_list_teaser .data .info .date {
  margin-right: 35px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_list_teaser .data .info .date div {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_list_teaser .data .info .date div img {
  padding-right: 8px;
}
.templates_news_list_teaser .data .info .file {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_list_teaser .data .info .file div {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_list_teaser .data .info .file div img {
  padding-right: 9px;
}
.templates_news_list_teaser .data .info .file a {
  text-decoration: none;
  color: #999999;
}
.templates_news_list_teaser .data .info .file a:hover {
  color: #000000;
}
.templates_news_list_teaser:hover .data .title a {
  color: #252525;
}
.templates_news_list_teaser:last-child {
  border: none;
  margin-bottom: 0;
}
body.mobile .templates_news_list_teaser {
  padding: 10px;
  flex-direction: column;
}
body.mobile .templates_news_list_teaser > .image {
  width: 100%;
  height: 0;
  padding-top: 71%;
  flex: unset;
}
body.mobile .templates_news_list_teaser > .data {
  width: 100%;
  padding: 10px;
  flex: unset;
}
body.mobile .templates_news_list_teaser > .data > .title {
  font-size: 20px;
}
body.mobile .templates_news_list_teaser > .data > .desc {
  height: 35px;
  font-size: 10px;
  margin-top: 0;
}
body.mobile .templates_news_list_teaser > .data > .info {
  margin-top: 40px;
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
}
