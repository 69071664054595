/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_topNavBar_topNavBar {
  display: none;
  width: calc(100% + 4vh);
  height: 3vh;
  margin-left: -2vh;
  background-color: #000000;
  color: #EEEEEE;
  line-height: 3vh;
  font-size: 1.5vh;
}
.templates_topNavBar_topNavBar .route {
  float: left;
  margin-left: 2vh;
}
.templates_topNavBar_topNavBar .menu {
  float: right;
  margin-right: 3.2vh;
}
.templates_topNavBar_topNavBar .menu a {
  color: inherit;
  text-decoration: none;
}
.templates_topNavBar_topNavBar .menu a:after {
  content: "|";
  margin: 0 0.5vh;
}
.templates_topNavBar_topNavBar .menu a:last-child:after {
  display: none;
}
.layouts_gridNavigator_gridNavigator.topNavBar .templates_topNavBar_topNavBar {
  display: block;
}
.layouts_gridNavigator_gridNavigator.topNavbar.backButton .templates_topNavBar_topNavBar {
  width: calc(100% - 8vh - 1px);
  margin-left: calc(10vh + 1px);
}
