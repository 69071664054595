/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_sidePanels_contact {
  background-color: inherit;
  width: 460px;
  height: 100vh;
  color: inherit;
}
.templates_headPanel_sidePanels_contact p,
.templates_headPanel_sidePanels_contact div {
  margin: 0 78px 0 78px;
}
.templates_headPanel_sidePanels_contact p p,
.templates_headPanel_sidePanels_contact div p {
  margin: 0;
}
.templates_headPanel_sidePanels_contact p.title,
.templates_headPanel_sidePanels_contact div.title {
  margin-top: 17vh;
  font-weight: 600;
  font-size: 5vh;
  color: #BBBBBB;
}
.templates_headPanel_sidePanels_contact p.mail,
.templates_headPanel_sidePanels_contact div.mail,
.templates_headPanel_sidePanels_contact p.contactForm,
.templates_headPanel_sidePanels_contact div.contactForm {
  margin-top: 8vh;
  font-weight: 600;
  font-size: 2.5vh;
}
.templates_headPanel_sidePanels_contact p.mail a,
.templates_headPanel_sidePanels_contact div.mail a,
.templates_headPanel_sidePanels_contact p.contactForm a,
.templates_headPanel_sidePanels_contact div.contactForm a {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}
.templates_headPanel_sidePanels_contact p.phone,
.templates_headPanel_sidePanels_contact div.phone,
.templates_headPanel_sidePanels_contact p.contactForm,
.templates_headPanel_sidePanels_contact div.contactForm {
  margin-top: 2vh;
  font-weight: 600;
  font-size: 2.5vh;
}
.templates_headPanel_sidePanels_contact p.info,
.templates_headPanel_sidePanels_contact div.info {
  margin-top: 8vh;
  line-height: 140%;
  font-size: 2.15vh;
  color: #BBBBBB;
}
body.mobile .templates_headPanel_sidePanels_contact {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
