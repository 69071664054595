/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_sidePanel {
  position: absolute;
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.templates_headPanel_sidePanel .background {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 301px);
  height: 100vh;
  background-color: #333333;
  opacity: 0;
}
.templates_headPanel_sidePanel .content {
  background-color: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 450px;
  height: 100vh;
  opacity: 1;
}
.templates_headPanel_sidePanel .content a:hover {
  cursor: pointer;
  text-decoration: underline;
}
.templates_headPanel_sidePanel.active {
  visibility: visible;
  opacity: 1;
}
.templates_headPanel_sidePanel.nav .content .navLogo {
  position: relative;
  margin: 3vh 5vh 2vh 5vh;
  width: 6vh;
}
.templates_headPanel_sidePanel.nav .content ul {
  position: relative;
  list-style: none;
  margin: 3vh 5vh 3vh 5vh;
  padding: 0 0 3vh 0;
  border-bottom: 1px solid #B9B9B9;
  font-weight: 600;
}
.templates_headPanel_sidePanel.nav .content ul:last-of-type {
  border-bottom: 0;
  padding: 0;
}
.templates_headPanel_sidePanel.nav .content ul li {
  font-size: 2vh;
  margin-bottom: 3vh;
}
.templates_headPanel_sidePanel.nav .content ul li:last-child {
  margin-bottom: 0;
}
.templates_headPanel_sidePanel.nav .content ul li a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}
.templates_headPanel_sidePanel.nav .content ul li a:hover {
  color: #006184;
}
.templates_headPanel_sidePanel.nav .content ul:first-of-type li {
  font-size: 3vh;
}
.templates_headPanel_sidePanel.nav .content p {
  margin: 3vh 5vh 0 5vh;
  border-top: 1px solid #B9B9B9;
  padding-top: 3vh;
  color: #656565;
  line-height: 180%;
  font-size: 1.6vh;
}
.templates_headPanel_sidePanel.nav .content p strong,
.templates_headPanel_sidePanel.nav .content p b {
  color: #333;
}
.templates_headPanel_sidePanel.contact .content {
  width: 460px;
  height: 100vh;
}
.templates_headPanel_sidePanel.contact-form {
  width: calc(100vw - 460px);
}
.templates_headPanel_sidePanel.contact-form .background {
  width: 100%;
}
.templates_headPanel_sidePanel.contact-form .content {
  width: calc(100% - 12vh);
  height: 100vh;
  box-sizing: border-box;
  padding: 0 78px;
}
.templates_headPanel_sidePanel.white .background {
  background-color: #333333;
}
.templates_headPanel_sidePanel.white .content {
  background-color: #FFFFFF;
  color: #B9B9B9;
}
.templates_headPanel_sidePanel.white.nav ul:first-of-type li {
  color: #B9B9B9;
}
.templates_headPanel_sidePanel.blue .background {
  background-color: #00273e;
}
.templates_headPanel_sidePanel.blue .content {
  background-color: #00273e;
  color: #CCCCCC;
  border-color: #333333;
}
.templates_headPanel_sidePanel.blue.nav ul:first-of-type li {
  color: #FFFFFF;
}
.templates_headPanel_sidePanel.black .background {
  background-color: #333333;
}
.templates_headPanel_sidePanel.black .content {
  background-color: #333333;
  color: #FFFFFF;
  border-color: #333333;
}
.templates_headPanel_sidePanel.black.nav ul:first-of-type li {
  color: #BBBBBB;
}
.layouts_gridNavigator_gridNavigator.topNavBar .templates_headPanel_sidePanel .content {
  padding-top: 3vh;
}
body.mobile .templates_headPanel_sidePanel {
  width: inherit;
}
body.mobile .templates_headPanel_sidePanel .background {
  width: 100%;
  height: 100%;
}
body.mobile .templates_headPanel_sidePanel .content {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 3vh;
}
body.mobile .templates_headPanel_sidePanel.active .content {
  width: calc(100% - 16vh);
}
body.mobile .templates_headPanel_sidePanel.active:first-child .content {
  width: calc(100% - 12vh);
}
body.mobile .templates_headPanel_sidePanel.active.contact-form .content {
  width: 100%;
}
body.mobile .templates_headPanel_sidePanel.subNav .content .navLogo,
body.mobile .templates_headPanel_sidePanel.nav .content .navLogo {
  visibility: hidden;
  width: 8vh;
  margin: 2vh;
}
body.mobile .templates_headPanel_sidePanel.subNav .content ul,
body.mobile .templates_headPanel_sidePanel.nav .content ul {
  margin: 4vh 4vh 7vh 4vh;
}
body.mobile .templates_headPanel_sidePanel.subNav .content ul li,
body.mobile .templates_headPanel_sidePanel.nav .content ul li {
  font-size: 3vh;
  margin-bottom: 3.8vh;
  font-weight: 600;
}
body.mobile .templates_headPanel_sidePanel.subNav .content ul:first-of-type li,
body.mobile .templates_headPanel_sidePanel.nav .content ul:first-of-type li {
  font-size: 3.8vh;
  margin-bottom: 3.8vh;
  font-weight: 700;
}
body.mobile .templates_headPanel_sidePanel.subNav .content p,
body.mobile .templates_headPanel_sidePanel.nav .content p {
  display: none;
}
body.mobile .templates_headPanel_sidePanel.subNav .content ul li {
  font-size: 2.8vh;
}
body.mobile .templates_headPanel_sidePanel.subNav .content ul:first-of-type {
  border-bottom: none;
  margin-bottom: 8vh;
  padding-bottom: 0;
}
@media print {
  .templates_headPanel_sidePanel {
    display: none;
  }
}
