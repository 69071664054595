/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_news_list_list {
  width: 100%;
  box-sizing: border-box;
}
.templates_news_list_list .noListDataFoundBar {
  background-color: #EDEDED;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}
