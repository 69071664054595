/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.polaroid {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #FFFFFF;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 30vw;
  height: 30vw;
  border: 2.2vh solid #FFFFFF;
  outline: 1px solid #EDEDED;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 20;
}
