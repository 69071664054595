/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_map_map {
  display: flex;
  flex-direction: column;
  max-width: 840px;
  width: calc(100% - 210px);
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 0 20px;
  font-weight: 300;
}
.templates_project_map_map .title h3 {
  float: left;
  font-size: 30px;
  color: #333333;
  line-height: 100%;
  font-weight: 300;
  padding: 40px 0 40px 40px;
  margin: 0;
}
.templates_project_map_map .content .texts {
  padding: 40px;
}
.templates_project_map_map .content .texts p {
  margin: 0;
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_project_map_map .content .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_project_map_map .content .map {
  right: 20px;
  top: 0;
  width: 100%;
  height: 86vh;
  max-width: 800px;
  max-height: 440px;
}
.templates_project_map_map .content .map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.templates_project_map_map.collapsable.open {
  padding-bottom: 0;
}
.templates_project_map_map.collapsable:not(.open) {
  height: 5vh !important;
}
body.breakPoint-l .templates_project_map_map .content .texts h3 {
  font-size: 5vh;
}
body.mobile .templates_project_map_map {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}
body.mobile .templates_project_map_map .title h3 {
  padding: 5px 10px 10px 10px;
  font-size: 20px;
  margin: 0;
}
body.mobile .templates_project_map_map .content .map {
  width: 100%;
  height: 50vh;
  margin: 0 0 20px 0;
  padding: 0;
}
body.mobile .templates_project_map_map .content .texts {
  margin-left: 0;
  width: 100%;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
}
body.mobile .templates_project_map_map .content .texts h3 {
  font-size: 20px;
}
body.mobile .templates_project_map_map .content .texts p {
  font-size: 12px;
  line-height: 150%;
}
