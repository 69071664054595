/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.polaroid {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #FFFFFF;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 30vw;
  height: 30vw;
  border: 2.2vh solid #FFFFFF;
  outline: 1px solid #EDEDED;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 20;
}
.templates_polaroid_1tl-2ir_1tl-2ir .texts {
  position: absolute;
  width: 32%;
  padding: 0;
  bottom: 15%;
  left: 6%;
}
.templates_polaroid_1tl-2ir_1tl-2ir .texts h3 {
  font-size: 5.6vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_polaroid_1tl-2ir_1tl-2ir .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_polaroid_1tl-2ir_1tl-2ir .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_polaroid_1tl-2ir_1tl-2ir .images {
  position: absolute;
  bottom: 15%;
  right: 8%;
  top: initial;
  left: initial;
  width: 49%;
  height: 85%;
}
.templates_polaroid_1tl-2ir_1tl-2ir .images .p1 {
  top: 7vh;
  left: 0;
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
  z-index: 101;
}
.templates_polaroid_1tl-2ir_1tl-2ir .images .p2 {
  top: 8vh;
  left: 17vw;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
  z-index: 100;
}
body.breakPoint-l .templates_polaroid_1tl-2ir_1tl-2ir .texts h3 {
  font-size: 5vh;
}
