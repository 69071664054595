/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_commercialProperties_object {
  float: left;
  clear: left;
  padding: 20px;
  width: 100%;
  margin-bottom: 12px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
}
.templates_commercialProperties_object:hover {
  cursor: pointer;
}
.templates_commercialProperties_object .image {
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: calc(35% - 2px);
  height: 0;
  box-sizing: border-box;
  padding-top: 24%;
}
.templates_commercialProperties_object .content {
  float: left;
  position: relative;
  margin-left: 20px;
  padding-top: 12px;
  width: calc(65% - 20px);
}
.templates_commercialProperties_object .content .info {
  position: relative;
  width: calc(100% - 2px);
}
.templates_commercialProperties_object .content .info .address {
  margin-top: 10px;
}
.templates_commercialProperties_object .content .info .address p {
  margin: 0;
  padding: 0;
}
.templates_commercialProperties_object .content .info .title {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.templates_commercialProperties_object .content .info .title:hover {
  text-decoration: underline;
}
.templates_commercialProperties_object .content .info .details {
  float: left;
}
.templates_commercialProperties_object .content .info .details p {
  margin: 20px 0 0 0;
}
.templates_commercialProperties_object .content .info .details table {
  border-spacing: 0;
  font-size: 1em;
  font-weight: 300;
  margin-top: 20px;
}
.templates_commercialProperties_object .content .info .details table td {
  padding: 0;
}
.templates_commercialProperties_object .content .info .details table td:nth-child(even) {
  font-weight: bold;
  padding-left: 1em;
}
body.mobile .templates_commercialProperties_object {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 12px;
}
body.mobile .templates_commercialProperties_object .image {
  width: 100%;
  padding-top: 68.5%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
body.mobile .templates_commercialProperties_object .content {
  width: 100%;
  padding: 10px 8px 0 8px;
  margin-left: 0;
  box-sizing: border-box;
}
body.mobile .templates_commercialProperties_object .content .title {
  font-size: 20px;
}
body.mobile .templates_commercialProperties_object .content .info .details table {
  font-size: 12px;
}
@media print {
  .templates_commercialProperties_object .image {
    -webkit-print-color-adjust: exact;
  }
}
