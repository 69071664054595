/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_standard_1tbl-1ir_1tbl-1ir .texts {
  position: absolute;
  width: 32%;
  padding: 0;
  bottom: 15%;
  left: 6%;
}
.templates_standard_1tbl-1ir_1tbl-1ir .texts h3 {
  font-size: 5.6vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_standard_1tbl-1ir_1tbl-1ir .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_standard_1tbl-1ir_1tbl-1ir .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_standard_1tbl-1ir_1tbl-1ir .images {
  position: absolute;
  right: 2vh;
  width: 55vw;
  height: 86vh;
}
.templates_standard_1tbl-1ir_1tbl-1ir .images.imgAttachedRight {
  right: 0;
}
.templates_standard_1tbl-1ir_1tbl-1ir .images img {
  width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.templates_standard_1tbl-1ir_1tbl-1ir .images.imgFullHeight {
  width: 55vw;
  height: 88vh;
}
.templates_standard_1tbl-1ir_1tbl-1ir .images.imgFullHeight div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
body.breakPoint-l .templates_standard_1tbl-1ir_1tbl-1ir .texts h3 {
  font-size: 5vh;
}
