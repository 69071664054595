/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_backButton {
  position: absolute;
  width: 100%;
  height: 3vw;
  font-size: 3vw;
  text-align: center;
  top: calc(50% - 1.5vw);
  color: #006184;
  z-index: 100;
}
.templates_headPanel_backButton img.logo {
  position: fixed;
  height: 80px;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.templates_headPanel_backButton img.logo.logoInverted {
  z-index: 101;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.templates_headPanel_backButton .arrow {
  width: 40%;
  margin-left: 30%;
  display: none;
}
body.mobile .templates_headPanel_backButton {
  display: none;
}
body.mobile .templates_headPanel_backButton img.logo {
  height: 50px;
  top: 13px;
  left: 13px;
}
