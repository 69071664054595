/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_standard_2-col-html_2-col-html .texts {
  position: absolute;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 0 0 0 50px;
}
.templates_standard_2-col-html_2-col-html .texts h2 {
  font-size: 5.6vh;
  color: #333333;
  line-height: 130%;
  margin: 0 0 40px 0;
}
.templates_standard_2-col-html_2-col-html .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: justify;
  line-height: 170%;
}
.templates_standard_2-col-html_2-col-html .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
body.breakPoint-l .templates_standard_2-col-html_2-col-html .texts h2 {
  font-size: 5vh;
}
body.mobile .templates_standard_2-col-html_2-col-html .texts {
  margin: 0;
}
body.mobile .templates_standard_2-col-html_2-col-html .texts h2 {
  margin-bottom: 20px;
}
body.mobile .templates_standard_2-col-html_2-col-html .texts .full {
  width: 100%;
  margin: 0;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_standard_2-col-html_2-col-html {
  min-height: unset !important;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_standard_2-col-html_2-col-html .texts {
  position: unset;
  height: auto;
}
.layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_2-col-html_2-col-html {
  max-width: 850px;
}
.layouts_gridNavigator_gridNavigator.maxWidthRegulation .templates_standard_2-col-html_2-col-html .texts {
  width: 100%;
}
