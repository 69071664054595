/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_sidePanels_contactFormSuccess {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -120px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #276681;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 300;
  transition: top 0.5s ease-in-out;
  z-index: 999999;
}
.templates_headPanel_sidePanels_contactFormSuccess.show {
  display: flex;
}
.templates_headPanel_sidePanels_contactFormSuccess.active {
  top: 0;
}
