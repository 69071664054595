/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.layouts_column_column {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  display: none;
  background-color: #FFFFFF;
  /* ---------- GRID SLIDERS --------- */
}
.layouts_column_column .windowWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.layouts_column_column .windowWrapper section.windows {
  height: 100%;
}
.layouts_column_column .sliderWrapper {
  position: absolute;
  width: 12px;
  -webkit-transition: width 0.2s;
  -moz-transition: width 0.2s;
  -ms-transition: width 0.2s;
  -o-transition: width 0.2s;
  transition: width 0.2s;
  transition-timing-function: ease-in-out;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 0;
}
.layouts_column_column .sliderWrapper:hover,
.layouts_column_column .sliderWrapper.active {
  width: 18px;
}
.layouts_column_column .sliderWrapper .slider {
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  display: none;
  z-index: 0;
}
.layouts_column_column .sliderWrapper .slider.blue {
  background-color: #006184;
}
.layouts_column_column .sliderWrapper .slider.darkBlue {
  background-color: #01273E;
}
.layouts_column_column .sliderWrapper .slider.active {
  display: block;
}
.layouts_column_column.active,
.layouts_column_column.actual {
  display: block;
  z-index: 1100;
}
.layouts_column_column.active .windowWrapper,
.layouts_column_column.actual .windowWrapper {
  z-index: 1101;
}
.layouts_column_column.active .windowWrapper .window,
.layouts_column_column.actual .windowWrapper .window {
  z-index: 1102;
}
.layouts_column_column.active .sliderWrapper,
.layouts_column_column.actual .sliderWrapper {
  z-index: 1150;
}
.layouts_column_column.active .sliderWrapper .slider,
.layouts_column_column.actual .sliderWrapper .slider {
  z-index: 1151;
}
.layouts_column_column.previous {
  z-index: 1000;
}
.layouts_column_column.previous .windowWrapper {
  z-index: 1001;
}
.layouts_column_column.previous .windowWrapper .window {
  z-index: 1002;
}
.layouts_column_column.previous .sliderWrapper {
  z-index: 1050;
}
.layouts_column_column.previous .sliderWrapper .slider {
  z-index: 1051;
}
.layouts_column_column.next {
  z-index: 1200;
}
.layouts_column_column.next .windowWrapper {
  z-index: 1201;
}
.layouts_column_column.next .windowWrapper .window {
  z-index: 1202;
}
.layouts_column_column.next .sliderWrapper {
  z-index: 1250;
}
.layouts_column_column.next .sliderWrapper .slider {
  z-index: 1251;
}
@media print {
  .layouts_column_column .windowWrapper {
    transform: unset !important;
    margin: 0 !important;
  }
}
body.mobile .layouts_column_column .sliderWrapper {
  width: 20px;
}
body.mobile .layouts_column_column .sliderWrapper:hover,
body.mobile .layouts_column_column .sliderWrapper.active {
  width: 20px;
}
.layouts_gridNavigator_gridNavigator.maxWidthRegulation .layouts_column_column .windowWrapper section.windows > * {
  max-width: 1048px !important;
}
body.mobile .layouts_gridNavigator_gridNavigator.maxWidthRegulation .layouts_column_column .windowWrapper section.windows > * {
  max-width: 100% !important;
  margin-bottom: 40px;
}
body.mobile .layouts_gridNavigator_gridNavigator.windowPadding:not(.endlessScroll) .layouts_column_column .windowWrapper section.windows > * {
  overflow: hidden;
}
body.mobile .layouts_gridNavigator_gridNavigator.windowPadding .layouts_column_column .windowWrapper section.windows {
  padding: 5px 18px 5px 18px;
}
.layouts_gridNavigator_gridNavigator.windowPadding .layouts_column_column .windowWrapper section.windows {
  padding: 5px 20px 5px 20px;
  box-sizing: border-box;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .layouts_column_column .windowWrapper section.windows {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .layouts_column_column .windowWrapper section.windows > * {
  height: auto;
  min-height: unset;
  margin-bottom: 60px;
  margin-top: 20px;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .layouts_column_column .windowWrapper section.windows > * > * {
  height: auto;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .layouts_column_column .sliderWrapper {
  display: none;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .layouts_column_column {
  background-color: #efefef;
}
.layouts_gridNavigator_gridNavigator.fullHeight .layouts_column_column:not(.fullHeight) .windowWrapper section.windows {
  height: calc(100% - 120px);
}
body.mobile .layouts_gridNavigator_gridNavigator.fullHeight .layouts_column_column:not(.fullHeight) .windowWrapper section.windows {
  height: 100%;
}
