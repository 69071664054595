/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_button_button {
  display: none;
}
button.templates_button_button {
  display: unset;
  padding: 2.2vh 3.5vh;
  margin-top: 3vh;
  font-size: 2.2vh;
  cursor: pointer;
  border-radius: 5px;
  color: #BBBBBB;
  background-color: #FFFFFF;
  border: 3px solid #BBBBBB;
  font-weight: 600;
  outline: 0;
}
button.templates_button_button:hover {
  border-color: #006184;
  background-color: #006184;
  color: #FFFFFF;
}
@media print {
  .templates_button_button {
    display: none;
  }
}
body.mobile .templates_button_button {
  border-color: #006184;
  background-color: #006184;
  color: #FFFFFF;
}
