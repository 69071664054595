/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
ul.templates_headPanel_columnMenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: right;
}
ul.templates_headPanel_columnMenu,
ul.templates_headPanel_columnMenu * {
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 600;
}
ul.templates_headPanel_columnMenu > li {
  width: auto;
  display: inline-block;
  line-height: 150%;
  font-size: 18px;
  text-align: center;
  margin: 0 12px;
  font-weight: 400;
  color: #BCBCBC;
}
ul.templates_headPanel_columnMenu > li a {
  cursor: pointer;
  color: inherit;
}
ul.templates_headPanel_columnMenu > li:hover,
ul.templates_headPanel_columnMenu > li.active {
  color: #006184;
}
body.mobile ul.templates_headPanel_columnMenu {
  width: 100%;
  margin-right: 0;
}
body.mobile ul.templates_headPanel_columnMenu li {
  width: 100%;
  text-align: center;
}
