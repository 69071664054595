/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
/* -----------------------------------------------------------------------------------
  MAIN NAV HAMBURGER ICON WITH TRANSFORMATION TO CLOSE BUTTON
-------------------------------------------------------------------------------------- */
.templates_headPanel_navButton {
  background-color: transparent;
  position: absolute;
  z-index: 3900;
  right: 10px;
  top: 27.5px;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 25px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
}
.templates_headPanel_navButton:focus {
  outline: none;
}
.templates_headPanel_navButton span {
  display: block;
  position: absolute;
  top: 10px;
  left: 4px;
  right: 4px;
  height: 5px;
  background: #276681;
}
.templates_headPanel_navButton span::before,
.templates_headPanel_navButton span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #276681;
  content: "";
}
.templates_headPanel_navButton span::before {
  top: -10px;
}
.templates_headPanel_navButton span::after {
  bottom: -10px;
}
.templates_headPanel_navButton.white span {
  background-color: #FFFFFF;
}
.templates_headPanel_navButton.white span::before,
.templates_headPanel_navButton.white span::after {
  background-color: #FFFFFF;
}
.templates_headPanel_navButton.blue span {
  background-color: #276681;
}
.templates_headPanel_navButton.blue span::before,
.templates_headPanel_navButton.blue span::after {
  background-color: #276681;
}
.templates_headPanel_navButton.black span {
  background-color: #333333;
}
.templates_headPanel_navButton.black span::before,
.templates_headPanel_navButton.black span::after {
  background-color: #333333;
}
.mainNavButtonX {
  /* active state, i.e. menu open */
}
.mainNavButtonX span {
  transition: background 0s 0.2s;
}
.mainNavButtonX span::before,
.mainNavButtonX span::after {
  transition-duration: 0.2s, 0.2s;
  transition-delay: 0.2s, 0s;
}
.mainNavButtonX span::before {
  transition-property: top, transform;
}
.mainNavButtonX span::after {
  transition-property: bottom, transform;
}
.mainNavButtonX.active span {
  background: none;
}
.mainNavButtonX.active span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mainNavButtonX.active span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.mainNavButtonX.active span::before,
.mainNavButtonX.active span::after {
  transition-delay: 0s, 0.2s;
  background-color: #666666;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_navButton span {
  background-color: #276681;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_navButton span::before,
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_navButton span::after {
  background-color: #276681;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_navButton.mainNavButtonX.active span {
  background: none;
}
.layouts_gridNavigator_gridNavigator.blackNavButton .templates_headPanel_navButton span {
  background-color: #B9B9B9;
}
.layouts_gridNavigator_gridNavigator.blackNavButton .templates_headPanel_navButton span::before,
.layouts_gridNavigator_gridNavigator.blackNavButton .templates_headPanel_navButton span::after {
  background-color: #B9B9B9;
}
.layouts_gridNavigator_gridNavigator.blackNavButton .templates_headPanel_navButton.mainNavButtonX.active span {
  background: none;
}
.layouts_gridNavigator_gridNavigator.whiteNavButton .templates_headPanel_navButton span {
  background-color: #FFFFFF;
}
.layouts_gridNavigator_gridNavigator.whiteNavButton .templates_headPanel_navButton span::before,
.layouts_gridNavigator_gridNavigator.whiteNavButton .templates_headPanel_navButton span::after {
  background-color: #FFFFFF;
}
.layouts_gridNavigator_gridNavigator.whiteNavButton .templates_headPanel_navButton.mainNavButtonX.active span {
  background: none;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_navButton {
  top: 12.5px;
}
body.mobile .layouts_gridNavigator_gridNavigator:not(.whiteNavButton):not(.blackNavButton) .templates_headPanel_navButton span {
  background-color: #276681;
}
body.mobile .layouts_gridNavigator_gridNavigator:not(.whiteNavButton):not(.blackNavButton) .templates_headPanel_navButton span::before,
body.mobile .layouts_gridNavigator_gridNavigator:not(.whiteNavButton):not(.blackNavButton) .templates_headPanel_navButton span::after {
  background-color: #276681;
}
body.mobile .layouts_gridNavigator_gridNavigator:not(.whiteNavButton):not(.blackNavButton) .templates_headPanel_navButton.mainNavButtonX.active span {
  background: none;
}
.layouts_gridNavigator_gridNavigator.fullHeight .templates_headPanel_navButton {
  margin: 20px;
}
