/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_objectList_categoryFilter select {
  font-size: 1em;
  padding: 0.325rem 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  background-color: #edf0f1;
}
.templates_project_objectList_categoryFilter select option {
  background-color: #FFFFFF;
}
.templates_project_objectList_categoryFilter select option:hover,
.templates_project_objectList_categoryFilter select option:active,
.templates_project_objectList_categoryFilter select option:focus {
  background-color: transparent;
}
