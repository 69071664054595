/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_logoMenu {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 20px;
  overflow: hidden;
  padding: 40px 0;
  box-sizing: border-box;
}
.templates_headPanel_logoMenu,
.templates_headPanel_logoMenu * {
  font-family: OpenSans, Arial, sans-serif;
  font-weight: 700;
}
.templates_headPanel_logoMenu > ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
  transform: translateY(-50%);
}
.templates_headPanel_logoMenu > ul > li {
  position: relative;
  float: left;
  margin-right: 16px;
  padding-right: 16px;
  border-right: 1px solid #CFCFCF;
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  line-height: 150%;
  white-space: nowrap;
}
.templates_headPanel_logoMenu > ul > li > a {
  text-decoration: none;
  color: inherit;
}
.templates_headPanel_logoMenu > ul > li:hover,
.templates_headPanel_logoMenu > ul > li.active {
  cursor: pointer;
  color: #006184;
}
.templates_headPanel_logoMenu > ul > li:last-child {
  border-right: none;
  margin-right: 0;
}
.layouts_gridNavigator_gridNavigator.fullHeight .templates_headPanel_logoMenu {
  display: none;
}
body.breakPoint-l .templates_headPanel_logoMenu > ul {
  float: none;
}
body.breakPoint-l .templates_headPanel_logoMenu > ul > li {
  float: none;
  margin-right: 0;
  padding-right: 0;
  border-right: none;
  margin-top: 0.8vh;
}
body.mobile .templates_headPanel_logoMenu {
  display: none;
}
