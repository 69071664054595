/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_window_window {
  float: left;
  clear: left;
  position: relative;
  width: 100%;
  min-height: 100%;
  margin-top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.templates_window_window.inactive {
  display: none;
}
.templates_window_window > [data-component-index]:not(.templates_window_segment) {
  min-height: 100%;
}
@media print {
  .templates_window_window:not(.actual) {
    display: none;
  }
  .templates_window_window.actual {
    height: 100% !important;
  }
  .templates_window_window.actual > * {
    height: 100% !important;
  }
  .templates_window_window.actual * {
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }
  .templates_window_window.actual .images > div {
    -webkit-print-color-adjust: exact;
  }
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_window_window {
  min-height: unset;
  overflow: unset;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_window_window > [data-component-index]:not(.templates_window_segment) {
  min-height: unset;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .templates_window_window {
  margin: 5px 0 !important;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .templates_window_window:first-child {
  margin-top: 0 !important;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .templates_window_window:last-child {
  margin-bottom: 0 !important;
}
