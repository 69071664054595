/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_breadCrumbs {
  display: none;
  width: 100%;
  height: 100px !important;
  padding: 45px 0 25px 0;
  margin: 0 0 10px 0 !important;
  box-sizing: border-box;
  list-style-type: none;
  overflow: hidden;
}
.templates_headPanel_breadCrumbs > li {
  position: relative;
  float: left;
  padding: 0 8px 0 0;
  margin-right: 0;
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  line-height: 150%;
  white-space: nowrap;
}
.templates_headPanel_breadCrumbs > li:first-letter {
  text-transform: capitalize;
}
.templates_headPanel_breadCrumbs > li > a {
  text-decoration: none;
  color: inherit;
}
.templates_headPanel_breadCrumbs > li:hover,
.templates_headPanel_breadCrumbs > li.active {
  cursor: pointer;
  color: #006184;
}
.templates_headPanel_breadCrumbs > li:last-child {
  border-right: none;
  margin-right: 0;
}
.templates_headPanel_breadCrumbs > li:last-child:hover {
  cursor: default;
  text-decoration: none;
}
.templates_headPanel_breadCrumbs .separator {
  position: relative;
  float: left;
  padding: 0 8px 0 0;
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  line-height: 150%;
}
.layouts_gridNavigator_gridNavigator.fullHeight .templates_headPanel_breadCrumbs {
  display: block;
}
body.breakPoint-l .templates_headPanel_breadCrumbs {
  float: left;
}
body.breakPoint-l .templates_headPanel_breadCrumbs > li {
  float: left;
  margin-top: 0;
}
body.mobile .templates_headPanel_breadCrumbs {
  display: none;
}
