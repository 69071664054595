/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_sliderGallery_sliderGallery {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.templates_sliderGallery_sliderGallery .frame {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  z-index: 1001;
  overflow: hidden;
}
.templates_sliderGallery_sliderGallery .galleryColumnWrapper {
  width: 100%;
  height: 100%;
  z-index: 999 !important;
}
.templates_sliderGallery_sliderGallery .gallerySliderWrapper {
  position: absolute;
  width: 100%;
  height: 2vh;
  top: calc(65% - 2vh);
  z-index: 1000 !important;
  overflow: hidden;
}
.templates_sliderGallery_sliderGallery .gallerySliderWrapper .gallerySlider {
  width: 0;
  height: 100%;
  background-color: #006184;
  -webkit-transition: height 0.2s, margin 0.2s;
  -moz-transition: height 0.2s, margin 0.2s;
  -ms-transition: height 0.2s, margin 0.2s;
  -o-transition: height 0.2s, margin 0.2s;
  transition: height 0.2s, margin 0.2s;
  transition-timing-function: ease-in-out;
}
.templates_sliderGallery_sliderGallery .gallerySliderWrapper .gallerySlider:hover {
  cursor: pointer;
  height: 3vh;
  margin-top: -1vh;
}
.templates_sliderGallery_sliderGallery .gallerySliderWrapper.active .gallerySlider {
  height: 3vh;
  margin-top: -1vh;
}
.templates_sliderGallery_sliderGallery.galleryFullHeight .gallerySliderWrapper {
  top: initial;
  bottom: 0;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight {
  height: auto;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight .gallerySliderWrapper {
  top: initial;
  margin-top: -2vh;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight.showFrame .gallerySliderWrapper {
  margin-top: calc(-2vh - 2px);
}
.templates_sliderGallery_sliderGallery.showFrame .frame {
  border: 2px solid #333333;
}
body.desktop .templates_sliderGallery_sliderGallery .gallerySliderWrapper {
  top: initial;
  bottom: 0;
}
@media print {
  .templates_sliderGallery_sliderGallery .galleryColumnWrapper {
    width: 100% !important;
    transform: unset !important;
    margin: 0 !important;
  }
}
