/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_window_segment {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* segment positions */
  /* segment background-colors */
}
.templates_window_segment.left {
  width: 50%;
  left: 0;
}
.templates_window_segment.right {
  width: 50%;
  left: 50%;
}
.templates_window_segment.top {
  height: 50%;
  top: 0;
}
.templates_window_segment.bottom {
  height: 50%;
  top: 50%;
}
.templates_window_segment .background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -ms-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.templates_window_segment .content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.templates_window_segment.white .background {
  background-color: #FFFFFF;
}
.templates_window_segment.white .content {
  color: #333333;
}
.templates_window_segment.gray .background {
  background-color: #ECEDF2;
}
.templates_window_segment.gray .content {
  color: #333333;
}
.templates_window_segment.lightBlue .background {
  background-color: #006184;
}
.templates_window_segment.lightBlue .content {
  color: #FFFFFF;
}
.templates_window_segment.blue .background {
  background-color: #033A58;
}
.templates_window_segment.blue .content {
  color: #FFFFFF;
}
.templates_window_segment.darkBlue .background {
  background-color: #01273E;
}
.templates_window_segment.darkBlue .content {
  color: #FFFFFF;
}
.templates_window_segment .content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.templates_window_segment:hover {
  cursor: pointer;
}
.templates_window_segment:hover > .background {
  opacity: 0.3;
}
.templates_window_segment h2 {
  position: relative;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 4vw;
  z-index: 3;
  display: block;
}
.templates_window_segment .templates_window_segment h2 {
  font-size: 2.5vw;
}
.templates_window_segment.windowSegmentGroup {
  background-color: transparent;
}
body.device-phone .templates_window_segment:hover .background,
body.device-tablet .templates_window_segment:hover .background {
  opacity: 1;
}
