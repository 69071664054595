/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_standard_html-mobile_html-mobile .texts {
  position: absolute;
  width: calc(100% - 4vh);
  height: calc(100% - 4vh);
  margin: 2vh;
  left: 0;
  top: 0;
}
.templates_standard_html-mobile_html-mobile .texts .title {
  margin: 1vh 0 4vh 0;
}
.templates_standard_html-mobile_html-mobile .texts .title h2 {
  font-size: 6.1vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_standard_html-mobile_html-mobile .texts .text {
  width: 100%;
  margin: 0;
}
.templates_standard_html-mobile_html-mobile .texts .text p {
  font-size: 2.2vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_standard_html-mobile_html-mobile .texts .text a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
