/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.polaroid {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #FFFFFF;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 30vw;
  height: 30vw;
  border: 2.2vh solid #FFFFFF;
  outline: 1px solid #EDEDED;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 20;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .texts {
  position: absolute;
  width: 32%;
  padding: 0;
  bottom: 15%;
  right: 6%;
  left: initial;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .texts h3 {
  font-size: 5.6vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .images {
  position: absolute;
  width: 32%;
  padding: 0;
  top: 1vh;
  left: 1vh;
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .images .p1 {
  top: 11vh;
  left: 4vw;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.templates_polaroid_2il-1tr-b_2il-1tr-b .images .p2 {
  top: 7vh;
  left: 22vw;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}
body.breakPoint-l .templates_polaroid_2il-1tr-b_2il-1tr-b .texts h3 {
  font-size: 5vh;
}
body.breakPoint-l .templates_polaroid_2il-1tr-b_2il-1tr-b .images {
  top: 12vh;
}
