/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_carousel_carousel .images {
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  z-index: 120;
  position: relative;
  left: 20px;
}
.templates_carousel_carousel .images div {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  height: 100%;
  display: none;
  float: left;
}
.templates_carousel_carousel .images div.active {
  display: block;
}
.templates_carousel_carousel .titles {
  z-index: 121;
  position: absolute;
  top: calc(50% - 10vh);
  width: 100%;
  text-align: center;
  font-weight: 700;
  color: #333333;
  font-size: 7vh;
}
.templates_carousel_carousel .titles a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.templates_carousel_carousel .titles a.active {
  color: inherit;
  text-decoration: underline;
}
