/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_presentation_presentation .images {
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  z-index: 120;
  position: relative;
  left: 20px;
}
.templates_project_presentation_presentation .images div {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  height: 100%;
  float: left;
}
.templates_project_presentation_presentation .texts {
  position: absolute;
  padding: 0;
  bottom: 15%;
  left: 6%;
  z-index: 121;
}
.templates_project_presentation_presentation .texts.blue {
  color: #006184;
}
.templates_project_presentation_presentation .texts.white {
  color: #FFFFFF;
}
.templates_project_presentation_presentation .texts.black {
  color: #333333;
}
.templates_project_presentation_presentation .texts h3 {
  font-size: 5.6vh;
  color: inherit;
  line-height: 120%;
  margin: 0;
}
.templates_project_presentation_presentation .texts p {
  font-size: 1.6vh;
  color: inherit;
  text-align: left;
  line-height: 170%;
}
.templates_project_presentation_presentation .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
body.breakPoint-l .templates_project_presentation_presentation .texts h3 {
  font-size: 5vh;
}
body.mobile .templates_project_presentation_presentation .texts {
  bottom: 8vh;
  left: 8vh;
}
