/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
body.mobile .mobile_collapsable_collapsable {
  min-height: unset !important;
}
body.mobile .mobile_collapsable_collapsable .toggle {
  float: right;
  width: 4vh;
  height: 4vh;
  color: #276584;
  line-height: 120%;
  font-weight: 400;
}
body.mobile .mobile_collapsable_collapsable .toggle > div.plus,
body.mobile .mobile_collapsable_collapsable .toggle > div.minus {
  width: 100%;
  height: 100%;
  background-image: url("/images/minus.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
body.mobile .mobile_collapsable_collapsable .toggle > div.plus {
  background-image: url("/images/plus.svg");
}
body.mobile .mobile_collapsable_collapsable.border {
  border-bottom: 0.325em solid #EEEEEE;
  padding-bottom: 20px;
}
body.mobile .mobile_collapsable_collapsable.collapsable {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
body.mobile .mobile_collapsable_collapsable .visibleWhenOpened,
body.mobile .mobile_collapsable_collapsable .visibleWhenClosed {
  display: none;
}
body.mobile .mobile_collapsable_collapsable.open {
  box-sizing: border-box;
}
body.mobile .mobile_collapsable_collapsable.open .visibleWhenOpened {
  display: block;
}
body.mobile .mobile_collapsable_collapsable:not(.open) .visibleWhenClosed {
  display: block;
}
body.mobile .mobile_collapsable_collapsable.templates_standard_html_html .texts .text > *:last-child {
  margin-bottom: 2vh;
}
