/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_commercialProperties_commercialProperties {
  float: left;
  clear: left;
  width: 100%;
  height: 100%;
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_commercialProperties_commercialProperties {
  min-height: unset !important;
}
