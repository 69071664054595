/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.polaroid {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #FFFFFF;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 30vw;
  height: 30vw;
  border: 2.2vh solid #FFFFFF;
  outline: 1px solid #EDEDED;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear;
  z-index: 20;
}
.templates_polaroid_3it-1tb_3it-1tb .texts {
  position: absolute;
  width: 64%;
  padding: 0;
  bottom: 5%;
  left: 18%;
  text-align: center;
  max-height: calc(100% - 25vw - 5%);
}
.templates_polaroid_3it-1tb_3it-1tb .texts h2 {
  width: 100%;
  text-align: center;
  font-size: 5.6vh;
  color: #333333;
  line-height: 120%;
  margin: 0;
}
.templates_polaroid_3it-1tb_3it-1tb .texts p {
  font-size: 1.6vh;
  color: #333333;
  text-align: left;
  line-height: 170%;
}
.templates_polaroid_3it-1tb_3it-1tb .texts a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
.templates_polaroid_3it-1tb_3it-1tb .texts.center p {
  text-align: center;
}
.templates_polaroid_3it-1tb_3it-1tb .images {
  position: absolute;
  top: 4.5vh;
  left: 1vh;
}
.templates_polaroid_3it-1tb_3it-1tb .images .polaroid {
  width: 18vw;
  height: 18vw;
  border-width: 1.4vh;
}
.templates_polaroid_3it-1tb_3it-1tb .images .polaroid.p1 {
  top: 0;
  left: 24vw;
}
.templates_polaroid_3it-1tb_3it-1tb .images .polaroid.p2 {
  top: 0;
  left: 38vw;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
}
.templates_polaroid_3it-1tb_3it-1tb .images .polaroid.p3 {
  top: 0;
  left: 54vw;
  -webkit-transform: rotate(-4deg);
  -moz-transform: rotate(-4deg);
  -ms-transform: rotate(-4deg);
  transform: rotate(-4deg);
}
body.breakPoint-l .templates_polaroid_3it-1tb_3it-1tb .texts h2 {
  font-size: 5vh;
}
