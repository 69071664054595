/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.mobile_button_button {
  align-self: flex-start;
  padding: 2.2vh 3.5vh;
  font-size: 2.2vh;
  cursor: pointer;
  background-color: #006184;
  border: 3px solid #BBBBBB;
  border-color: #006184;
  font-weight: 600;
  color: #FFFFFF;
}
@media print {
  .mobile_button_button {
    display: none;
  }
}
