/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_description {
  padding: 20px !important;
  font-weight: 300;
  font-style: italic;
  font-size: 15px;
  text-align: center;
  color: #333333;
}
.templates_project_gallery_description.isHidden {
  display: none;
}
.templates_project_gallery_description h2,
.templates_project_gallery_description h3 {
  font-size: 30px;
  color: #333333;
  line-height: 120%;
  font-weight: 300;
  margin: 0;
}
.templates_project_gallery_description table {
  font-size: inherit;
  font-weight: 300;
  margin-top: 20px;
  width: 100%;
}
.templates_project_gallery_description p {
  margin: 0;
}
