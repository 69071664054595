/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_news_news_news {
  margin-bottom: 15px;
}
.templates_news_news_news .templates_project_gallery_gallery {
  padding-top: 20px;
  margin-bottom: 10px;
}
.templates_news_news_news > .content {
  padding: 25px 60px 35px 60px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  max-width: 840px;
  width: calc(100% - 210px);
  font-size: 16px;
  font-weight: 300;
  line-height: 180%;
}
.templates_news_news_news > .content .title,
.templates_news_news_news > .content h2,
.templates_news_news_news > .content h3 {
  margin-top: 15px;
  font-size: 30px;
  font-weight: 300;
  line-height: 130%;
}
.templates_news_news_news > .content ul {
  list-style-type: square;
  padding: 0 0 0 1.25em;
}
.templates_news_news_news > .content .info {
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  margin-top: 40px;
  color: #999999;
}
.templates_news_news_news > .content .info .date {
  margin-right: 35px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_news_news > .content .info .date div {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_news_news > .content .info .date div img {
  padding-right: 8px;
}
.templates_news_news_news > .content .info .file {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_news_news > .content .info .file div {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_news_news_news > .content .info .file div img {
  padding-right: 9px;
}
.templates_news_news_news > .content .info .file a {
  text-decoration: none;
  color: #999999;
}
.templates_news_news_news > .content .info .file a:hover {
  color: #000000;
}
body.mobile .templates_news_news_news .templates_project_gallery_gallery {
  padding-top: 10px !important;
}
body.mobile .templates_news_news_news > .content {
  padding: 12px 20px 20px 20px;
  width: 100%;
  font-size: 10px;
}
body.mobile .templates_news_news_news > .content .title,
body.mobile .templates_news_news_news > .content h2,
body.mobile .templates_news_news_news > .content h3 {
  font-size: 20px;
  margin-top: 8px;
}
body.mobile .templates_news_news_news > .content > .info > * {
  white-space: nowrap;
}
