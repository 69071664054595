/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.mobile_1it-1tbb-base_1it-1tbb-base {
  width: 100%;
  height: 100%;
}
.mobile_1it-1tbb-base_1it-1tbb-base .imagesMobile {
  width: 100%;
  height: calc(55% - 4vh);
  margin-bottom: 4vh;
  overflow: hidden;
}
.mobile_1it-1tbb-base_1it-1tbb-base .imagesMobile.buttonLess {
  height: calc(70% - 4vh);
}
.mobile_1it-1tbb-base_1it-1tbb-base .imagesMobile div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  margin: 0;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100% - 8vh);
  padding-bottom: 4vh;
  box-sizing: border-box;
  margin: 0 4vh;
  height: 45%;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile.buttonLess {
  height: 30%;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile h3 {
  font-size: 4vh;
  color: #333333;
  line-height: 120%;
  margin: 0 0 2vh 0;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile p {
  font-size: 2vh;
  color: #333333;
  text-align: left;
  line-height: 150%;
  margin-top: 0;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile ul {
  list-style-type: square;
  padding-left: 2vh;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile ul li {
  font-size: 2vh;
}
.mobile_1it-1tbb-base_1it-1tbb-base .textsMobile a {
  color: #006184;
  font-weight: bold;
  text-decoration: none;
}
