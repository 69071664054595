/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_sliderGallery_image {
  float: left;
  width: 0;
  height: 100%;
  box-sizing: border-box;
}
.templates_sliderGallery_image .image {
  width: 100%;
  height: 65%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.templates_sliderGallery_image .image.loading {
  background-image: url(/images/loading.gif);
  background-size: 100px;
}
.templates_sliderGallery_image .content {
  width: 100%;
  height: 35%;
  box-sizing: border-box;
  padding: 2vh;
}
.templates_sliderGallery_image .content h3 {
  margin-top: 0;
  font-size: 3vh;
}
.templates_sliderGallery_sliderGallery.galleryFullHeight .templates_sliderGallery_image .image {
  height: 100%;
}
.templates_sliderGallery_sliderGallery.galleryFullHeight .templates_sliderGallery_image .content {
  display: none;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight .templates_sliderGallery_image {
  height: 100%;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight .templates_sliderGallery_image .image {
  height: 100%;
}
.templates_sliderGallery_sliderGallery.galleryLandscapeCustomHeight .templates_sliderGallery_image .content {
  display: none;
}
body.desktop .templates_sliderGallery_image {
  padding: 0 2vh 2vh 0;
}
body.desktop .templates_sliderGallery_image .image {
  width: 72%;
  height: 100%;
  float: right;
}
body.desktop .templates_sliderGallery_image .content {
  float: right;
  width: 23%;
  height: 30vh;
  margin-top: 56vh;
  padding: 2vh;
}
body.desktop .templates_sliderGallery_image .content h3 {
  font-size: 5vh;
  margin-bottom: 1vh;
}
body.desktop .templates_sliderGallery_image .content p {
  font-size: 1.6vh;
}
@media print {
  .templates_sliderGallery_image:not(.actual) {
    display: none;
  }
  .templates_sliderGallery_image.actual {
    width: 100%;
  }
  .templates_sliderGallery_image.actual > * {
    display: none;
  }
}
