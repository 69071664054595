/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_privacyOverlay_privacyOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}
.templates_privacyOverlay_privacyOverlay.disabled {
  display: none;
}
.templates_privacyOverlay_privacyOverlay .closeBut {
  color: #FFFFFF;
  font-size: 42px;
  position: relative;
  float: right;
  margin: 20px 30px 0 0;
  line-height: 42px;
  cursor: pointer;
}
.templates_privacyOverlay_privacyOverlay .closeBut:hover {
  color: #CCCCCC;
}
.templates_privacyOverlay_privacyOverlay .container {
  max-width: 850px;
  padding: 30px 35px 10px 35px;
}
.templates_privacyOverlay_privacyOverlay .container .title {
  font-weight: bold;
}
.templates_privacyOverlay_privacyOverlay .container .title,
.templates_privacyOverlay_privacyOverlay .container .info {
  color: #FFFFFF;
  line-height: 1.6;
  font-size: 18px;
}
.templates_privacyOverlay_privacyOverlay .container .buttons-container {
  margin: 30px 0 40px 0;
}
.templates_privacyOverlay_privacyOverlay .container .buttons-container button {
  padding: 20px 30px;
  margin: 0 25px 0 0;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #006184;
  border: 1px solid #006184;
  font-weight: 600;
  outline: 0;
}
.templates_privacyOverlay_privacyOverlay .container .buttons-container button:last-child {
  margin-right: 0;
}
.templates_privacyOverlay_privacyOverlay .container .buttons-container button:hover {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #333333;
}
.templates_privacyOverlay_privacyOverlay .container nav {
  border-top: 2px #FFFFFF solid;
  padding-top: 30px;
}
.templates_privacyOverlay_privacyOverlay .container nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.templates_privacyOverlay_privacyOverlay .container nav ul li {
  display: inline-block;
  margin: 0 25px 20px 0;
}
.templates_privacyOverlay_privacyOverlay .container nav ul li a {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}
.templates_privacyOverlay_privacyOverlay .container nav ul li:last-child {
  margin-right: 0;
}
body.mobile .templates_privacyOverlay_privacyOverlay {
  max-width: unset;
  top: 0;
  bottom: auto;
  left: 0;
  height: 100%;
}
body.mobile .templates_privacyOverlay_privacyOverlay .closeBut {
  font-size: 64px;
  margin: 10px 20px 0 0;
  line-height: 64px;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container {
  padding: 75px 20px 10px 20px;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container .title,
body.mobile .templates_privacyOverlay_privacyOverlay .container .info {
  line-height: 1.4;
  font-size: 14px;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container .buttons-container {
  margin: 20px 0 25px 0;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container .buttons-container button {
  padding: 16px 15px;
  margin: 0 20px 0 0;
  font-size: 16px;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container nav {
  padding-top: 20px;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container nav ul li {
  display: inline-block;
  margin: 0 20px 8px 0;
}
body.mobile .templates_privacyOverlay_privacyOverlay .container nav ul li a {
  font-size: 15px;
}
