/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_privacyOverlay_privacyOverlayConfirmation {
  position: absolute;
  top: 12vh;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10001;
  display: none;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container {
  margin: 0 auto;
  padding: 30px 35px 10px 35px;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container .info {
  color: #FFFFFF;
  line-height: 1.6;
  font-size: 18px;
  text-align: center;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container .info .denied,
.templates_privacyOverlay_privacyOverlayConfirmation .container .info .accepted {
  display: none;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container .buttons-container {
  margin: 30px 0 40px 0;
  text-align: center;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container .buttons-container button {
  padding: 20px 30px;
  min-width: 160px;
  margin: 0;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #006184;
  border: 1px solid #006184;
  font-weight: 600;
  outline: 0;
}
.templates_privacyOverlay_privacyOverlayConfirmation .container .buttons-container button:hover {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #333333;
}
