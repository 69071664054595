/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_rightSideBar {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 840px;
  margin-top: 20px;
}
.templates_project_gallery_rightSideBar > section > * {
  padding: 30px 10px 30px 30px;
}
.templates_project_gallery_rightSideBar.fullHeight {
  height: 100%;
  box-sizing: content-box;
}
.templates_project_gallery_rightSideBar.fullHeight > .templates_project_contact_contact {
  display: flex;
  flex-direction: column;
}
body.mobile .templates_project_gallery_rightSideBar {
  display: flex;
  margin-top: 0;
  width: 100%;
}
body.mobile .templates_project_gallery_rightSideBar > section.top {
  display: flex;
  flex-direction: row;
}
