/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_sidePanels_contactForm h1 {
  margin-top: 17vh;
  font-size: 5vh;
}
.templates_headPanel_sidePanels_contactForm .base_form_elements_label {
  display: none;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field {
  margin-bottom: 20px;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .base_form_fields_checkbox input {
  margin: 5px 10px 20px 0;
  float: left;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .base_form_fields_checkbox .description {
  width: calc(100% - 50px);
  display: inline-block;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field.validationFailed .base_form_fields_checkbox .description {
  color: #FF0000;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"],
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"],
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  border-radius: 5px;
  padding: 10px 0 10px 10px;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"].validationFailed,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"].validationFailed,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select.validationFailed,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea.validationFailed {
  border-color: #FF0000;
  outline-color: #FF0000;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"]::-webkit-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"]::-webkit-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select::-webkit-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #CCCCCC;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"]::-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"]::-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select::-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #CCCCCC;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"]:-ms-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"]:-ms-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select:-ms-input-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #CCCCCC;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"]:-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"]:-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select:-moz-placeholder,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #CCCCCC;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  font-size: 1em;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input:focus,
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea:focus {
  border-color: #266681;
  outline: 2px solid #266681;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  height: 160px;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field > *:not(.fieldIndependent) {
  width: 100% !important;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field.validationFailed {
  color: #266681;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field.validationFailed label {
  color: #266681;
}
.templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .validationError {
  display: none;
  margin-top: -1em;
  background-color: #266681;
}
.templates_headPanel_sidePanels_contactForm .buttons button,
.templates_headPanel_sidePanels_contactForm .buttons button.positive,
.templates_headPanel_sidePanels_contactForm .buttons button.negative {
  float: left;
  padding: 2.2vh 3.5vh;
  margin-left: 0;
  margin-top: 3vh;
  font-size: 2.2vh;
  cursor: pointer;
  border-radius: 5px;
  color: #BBBBBB;
  background-color: #FFFFFF;
  border: 3px solid #BBBBBB;
  font-weight: 600;
  outline: 0;
}
.templates_headPanel_sidePanels_contactForm .buttons button i,
.templates_headPanel_sidePanels_contactForm .buttons button.positive i,
.templates_headPanel_sidePanels_contactForm .buttons button.negative i {
  display: none;
}
.templates_headPanel_sidePanels_contactForm .buttons button:hover,
.templates_headPanel_sidePanels_contactForm .buttons button.positive:hover,
.templates_headPanel_sidePanels_contactForm .buttons button.negative:hover,
.templates_headPanel_sidePanels_contactForm .buttons button:focus,
.templates_headPanel_sidePanels_contactForm .buttons button.positive:focus,
.templates_headPanel_sidePanels_contactForm .buttons button.negative:focus {
  border-color: #006184;
  background-color: #006184;
  color: #FFFFFF;
}
body.mobile .templates_headPanel_sidePanels_contactForm h1,
body.device-tablet .templates_headPanel_sidePanels_contactForm h1 {
  margin-top: 8vh;
  margin-bottom: 4vh;
  font-size: 5vh;
}
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea,
body.device-tablet .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  height: 60px;
}
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .base_form_fields_checkbox .description,
body.device-tablet .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .base_form_fields_checkbox .description {
  font-size: 13px;
}
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button,
body.device-tablet .templates_headPanel_sidePanels_contactForm .buttons button,
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button.positive,
body.device-tablet .templates_headPanel_sidePanels_contactForm .buttons button.positive,
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button.negative,
body.device-tablet .templates_headPanel_sidePanels_contactForm .buttons button.negative {
  margin-top: 20px;
}
body.mobile .templates_headPanel_sidePanels_contactForm h1 {
  margin-top: 12.5px;
  margin-bottom: 20px;
  font-size: 1.75em;
  line-height: 200%;
}
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="text"],
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field input[type="password"],
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field select,
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  padding: 5px 0 5px 5px;
}
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field textarea {
  height: 60px;
}
body.mobile .templates_headPanel_sidePanels_contactForm.base_form_form .cols .col .field .base_form_fields_checkbox .description {
  font-size: 12px;
}
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button,
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button.positive,
body.mobile .templates_headPanel_sidePanels_contactForm .buttons button.negative {
  margin-top: 20px;
}
