/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_pricing {
  background-color: #FFFFFF;
  font-weight: 300;
  padding: 20px;
  margin-bottom: 10px;
}
.templates_project_gallery_pricing.isHidden {
  display: none;
}
.templates_project_gallery_pricing h2,
.templates_project_gallery_pricing h3 {
  font-size: 30px;
  color: #333333;
  line-height: 120%;
  font-weight: 300;
  margin: 0;
}
.templates_project_gallery_pricing > div {
  font-weight: 300;
  margin-top: 20px;
  width: 100%;
}
.templates_project_gallery_pricing > div > p {
  margin: 0 0 10px 0;
  font-size: 14px;
}
.templates_project_gallery_pricing > div > p.small {
  font-size: 10px;
}
body.mobile .templates_project_gallery_pricing {
  font-size: 10px;
  padding: 18px !important;
  margin-left: 10px;
  flex: 1 1 auto;
}
body.mobile .templates_project_gallery_pricing h2,
body.mobile .templates_project_gallery_pricing h3 {
  font-size: 20px;
}
body.mobile .templates_project_gallery_pricing > div > p {
  font-size: 10px;
  margin-bottom: 5px;
}
