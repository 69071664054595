/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_headPanel_headPanel {
  position: fixed;
  height: 120px;
  width: inherit;
  padding: 20px 0 20px 0;
  box-sizing: border-box;
  z-index: 3000;
}
.templates_headPanel_headPanel section.backPanel {
  position: fixed;
  width: 121px;
  height: 0;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background-color: #FFFFFF;
  border-right: 1px solid #CFCFCF;
  z-index: 100;
  overflow: hidden;
}
.templates_headPanel_headPanel section.backPanel .arrow.gray {
  display: block;
}
.templates_headPanel_headPanel section.backPanel:hover {
  cursor: pointer;
  background-color: #006184;
  border-right: 1px solid #CFCFCF;
}
.templates_headPanel_headPanel section.backPanel:hover .templates_headPanel_backButton {
  color: #FFFFFF;
}
.templates_headPanel_headPanel section.backPanel:hover .templates_headPanel_backButton img.logoInverted {
  opacity: 1;
}
.templates_headPanel_headPanel section.backPanel:hover .templates_headPanel_backButton .arrow {
  display: none !important;
}
.templates_headPanel_headPanel section.backPanel:hover .templates_headPanel_backButton .arrow.white {
  display: block !important;
}
.templates_headPanel_headPanel .left {
  position: relative;
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.templates_headPanel_headPanel .left .logo {
  position: relative;
  height: 80px;
  top: 0;
  left: 0;
  display: block;
  z-index: 4;
}
.templates_headPanel_headPanel .left .logo:hover {
  cursor: pointer;
}
.templates_headPanel_headPanel .menu {
  position: relative;
  float: left;
  width: 300pt;
  padding-right: 1vh;
  border: 1px solid #CFCFCF;
  border-width: 0;
  height: 100%;
  visibility: hidden;
}
.templates_headPanel_headPanel .columnMenu {
  float: left;
  width: calc(100% - 300pt - 200px);
  height: 100%;
  visibility: hidden;
  box-sizing: border-box;
  padding: 40px 20px 40px 0;
}
.templates_headPanel_headPanel .right {
  float: right;
  position: relative;
}
.templates_headPanel_headPanel section.sidePanels {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3001;
  visibility: hidden;
}
.templates_headPanel_headPanel section.sidePanels.active {
  visibility: visible;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel {
  background-color: #FFFFFF;
  border-bottom: 1px solid #CFCFCF;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel .menu {
  visibility: visible;
}
.layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel .columnMenu {
  visibility: visible;
}
body.mobile .layouts_gridNavigator_gridNavigator.gridBorder .templates_headPanel_headPanel {
  box-shadow: 0 0 15px 0 #666666;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_headPanel {
  height: 76px;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_headPanel section.backPanel {
  width: 77px;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_headPanel .left {
  width: 50px;
  height: 50px;
  margin-right: 13px;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_headPanel .left .logo {
  height: 50px;
}
body.mobile .layouts_gridNavigator_gridNavigator .templates_headPanel_headPanel section.menu {
  width: calc(100% - 150px);
  padding: 0;
}
body.mobile .layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel {
  border-bottom: none;
}
body.mobile .layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel section.menu {
  display: none;
}
body.mobile .layouts_gridNavigator_gridNavigator.columnMenu .templates_headPanel_headPanel section.columnMenu {
  width: calc(100% - 125px);
  padding: 25px 13px 25px 0;
  text-align: center;
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel {
  background-color: #FFFFFF;
  border-bottom: none;
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel .menu {
  border: none;
  visibility: visible;
  width: calc(100% - 22vh);
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel .menu .templates_headPanel_logoMenu > ul.breadCrumbs {
  display: block;
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel .columnMenu {
  display: none;
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel section.backPanel {
  height: 100%;
  opacity: 1;
}
.layouts_gridNavigator_gridNavigator.backButton .templates_headPanel_headPanel .left .logo {
  top: 20px;
  left: 20px;
}
.layouts_gridNavigator_gridNavigator.topNavBar.backButton .templates_headPanel_headPanel {
  height: 9vh;
  padding-top: 0;
}
.layouts_gridNavigator_gridNavigator.coloredBackground .templates_headPanel_headPanel {
  background-color: unset;
}
.layouts_gridNavigator_gridNavigator.fullHeight .templates_headPanel_headPanel {
  position: unset;
  height: 0;
  padding: 0;
}
body.breakPoint-l .templates_headPanel_headPanel .menu {
  width: 20vh;
}
body.breakPoint-l .templates_headPanel_headPanel .columnMenu {
  width: calc(100% - 40vh);
}
@media print {
  .templates_headPanel_headPanel .backPanel,
  .templates_headPanel_headPanel .columnMenu {
    display: none;
  }
}
