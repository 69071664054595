/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.templates_project_gallery_gallery {
  max-width: 840px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  width: calc(100% - 210px);
  background-color: #FFFFFF;
}
.templates_project_gallery_gallery.isHidden {
  display: none;
}
.templates_project_gallery_gallery h2 {
  color: #333333;
  padding: 40px 0 40px 40px;
  margin: 0;
  font-size: 30px;
  line-height: 120%;
  font-weight: bold;
  overflow: hidden;
}
.templates_project_gallery_gallery .background {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: #000000;
  opacity: 0.7;
  z-index: 99998;
}
.templates_project_gallery_gallery .frame {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #EDF0F1;
}
.templates_project_gallery_gallery .image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: calc(100% - 230px);
  padding-top: 68.75%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.templates_project_gallery_gallery .image .center {
  position: absolute;
  height: 100%;
  width: 60%;
  left: 20%;
  top: 0;
}
.templates_project_gallery_gallery .image .center .fullScreen {
  width: 100%;
  height: 100%;
}
.templates_project_gallery_gallery .image .left,
.templates_project_gallery_gallery .image .right {
  position: absolute;
  height: 100%;
  width: 20%;
  left: 0;
  top: 0;
}
.templates_project_gallery_gallery .image .left .arrow,
.templates_project_gallery_gallery .image .right .arrow {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #FFFFFF;
  background-image: url("/images/gallery_back.svg");
  background-size: contain;
  opacity: 0.8;
}
.templates_project_gallery_gallery .image .left .arrow > *,
.templates_project_gallery_gallery .image .right .arrow > * {
  display: none;
}
.templates_project_gallery_gallery .image .left:hover,
.templates_project_gallery_gallery .image .right:hover {
  cursor: pointer;
}
.templates_project_gallery_gallery .image .left:hover .arrow,
.templates_project_gallery_gallery .image .right:hover .arrow {
  opacity: 1;
}
.templates_project_gallery_gallery .image .right {
  left: unset;
  right: 0;
}
.templates_project_gallery_gallery .image .right .close {
  display: none;
  position: absolute;
  top: 20px;
  right: -50px;
  width: 50px;
  height: 50px;
  padding: 12.5px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  background-image: url("/images/gallery_close.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.templates_project_gallery_gallery .image .right .arrow {
  left: unset;
  right: 20%;
  background-image: url("/images/gallery_fwd.svg");
}
.templates_project_gallery_gallery .imageList {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0px;
  width: 230px;
  height: 100%;
  text-align: right;
}
.templates_project_gallery_gallery .imageList .thumb {
  background-color: #000;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
}
.templates_project_gallery_gallery .imageList .thumb div {
  opacity: 0.4;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.templates_project_gallery_gallery .imageList .thumb.active div,
.templates_project_gallery_gallery .imageList .thumb:hover div {
  opacity: 1;
}
.templates_project_gallery_gallery.hiddenThumbs .image {
  width: 100% !important;
}
.templates_project_gallery_gallery.hiddenThumbs .imageList {
  display: none;
}
.templates_project_gallery_gallery.fullScreenGallery {
  position: fixed;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  padding: 0;
  z-index: 99999;
  max-width: unset;
  background-color: transparent;
}
.templates_project_gallery_gallery.fullScreenGallery .background {
  display: block;
}
.templates_project_gallery_gallery.fullScreenGallery .frame {
  overflow: visible;
  margin: 70px 190px;
  background-color: transparent;
  box-sizing: border-box;
  width: calc(100% - 380px);
  height: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.templates_project_gallery_gallery.fullScreenGallery .frame.frameWithHeadline {
  height: 100%;
}
.templates_project_gallery_gallery.fullScreenGallery .image {
  width: unset !important;
  height: unset;
  z-index: 99999;
  background-color: #FFFFFF;
  padding: 10px;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}
.templates_project_gallery_gallery.fullScreenGallery .image > img {
  max-width: 100%;
  max-height: 100%;
}
.templates_project_gallery_gallery.fullScreenGallery .image .right .close {
  display: block;
}
.templates_project_gallery_gallery.isInlineGallery h2 {
  font-weight: 300;
}
.templates_project_gallery_gallery.size1 .imageList .thumb,
.templates_project_gallery_gallery.size2 .imageList .thumb,
.templates_project_gallery_gallery.size3 .imageList .thumb,
.templates_project_gallery_gallery.size4 .imageList .thumb {
  height: 25%;
}
.templates_project_gallery_gallery.size5 .imageList .thumb {
  height: 20%;
}
.templates_project_gallery_gallery.size6 .image {
  width: calc(100% - 200px);
}
.templates_project_gallery_gallery.size6 .imageList {
  width: 200px;
}
.templates_project_gallery_gallery.size6 .imageList .thumb {
  height: 16.6%;
}
.templates_project_gallery_gallery.size7 .image {
  width: calc(100% - 200px);
}
.templates_project_gallery_gallery.size7 .imageList {
  width: 200px;
}
.templates_project_gallery_gallery.size7 .imageList .thumb {
  height: 14.2%;
}
.templates_project_gallery_gallery.size8 .image {
  width: calc(100% - 200px);
}
.templates_project_gallery_gallery.size8 .imageList {
  width: 200px;
}
.templates_project_gallery_gallery.size8 .imageList .thumb {
  height: 12.5%;
}
.templates_project_gallery_gallery.size9 .image {
  width: calc(100% - 200px);
}
.templates_project_gallery_gallery.size9 .imageList {
  width: 200px;
}
.templates_project_gallery_gallery.size9 .imageList .thumb {
  height: 11.1%;
}
.templates_project_gallery_gallery.size10 .image {
  width: calc(100% - 180px);
}
.templates_project_gallery_gallery.size10 .imageList {
  width: 180px;
}
.templates_project_gallery_gallery.size10 .imageList .thumb {
  height: 10%;
}
.templates_project_gallery_gallery .templates_project_gallery_rightSideBar {
  display: flex;
  position: absolute;
  width: 200px;
  right: 0;
  transform: translateY(-100%);
}
.templates_project_gallery_gallery.singleImageGallery .left:hover,
.templates_project_gallery_gallery.singleImageGallery .right:hover {
  cursor: default;
}
.templates_project_gallery_gallery.emptyGallery {
  display: none;
}
@media print {
  .templates_project_gallery_gallery .imageList .thumb {
    -webkit-print-color-adjust: exact;
  }
  .templates_project_gallery_gallery .image {
    -webkit-print-color-adjust: exact;
  }
}
.layouts_gridNavigator_gridNavigator.endlessScroll .templates_project_gallery_gallery {
  min-height: unset !important;
}
body.mobile .layouts_gridNavigator_gridNavigator.endlessScroll .templates_project_gallery_gallery {
  width: 100%;
  padding: 0 10px 10px 10px;
}
body.mobile .templates_project_gallery_gallery {
  width: 100%;
}
body.mobile .templates_project_gallery_gallery h2 {
  font-size: 15px;
  padding: 26px 10px 26px 10px;
}
body.mobile .templates_project_gallery_gallery .templates_project_gallery_rightSideBar {
  display: none;
}
