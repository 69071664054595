/* COLORS - HEAD PANEL */
/* COLORS - TOP NAV BAR */
/* COLORS - BACK PANEL */
/* COLORS - HOME */
/* COLORS - SUBMENU */
/* COLORS - MAIN-NAV-BUTTON */
/* COLORS - MAIN-NAV-PANEL */
/* COLORS - TEAM */
/* POLAROID-TEMPLATES */
/* COLORS - ERROR */
.fi {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");font-weight: var(--fa-style, 900);
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-participant {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: var(--fa-display, inline-block);font-style: normal;font-variant: normal;line-height: 1;text-rendering: auto;
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-participant {
  font-family: 'Font Awesome 6 Free';
}
.fi.fi-trash::before {
  content: "\f2ed";
}
.fi.fi-file::before,
.fi.fi-file-o::before,
.fi.fi-file-text-o::before,
.fi.fi-media-txt::before {
  content: "\f15c";
}
.fi.fi-calendar::before,
.fi.fi-newsletter-segment-segmentData-fieldType-date::before {
  content: "\f073";
}
.fi.fi-sort-desc::before {
  content: "\f0dd";
}
.fi.fi-minus-circle::before {
  content: "\f056";
}
.fi.fi-sign-out::before {
  content: "\f2f5";
}
.fi.fi-edit-slim::before {
  content: "\f303";
}
.fi.fi-minus-square::before,
.fi.fi-minus-square::before {
  content: "\f146";
}
.fi.fi-exclamation-circle::before,
.fi.fi-error::before {
  content: "\f06a";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list::before {
  content: "\f03a";
}
.fi.fi-lock::before {
  content: "\f023";
}
.fi.fi-edit::before {
  content: "\f044";
}
.fi.fi-share-alt::before,
.fi.fi-assign::before,
.fi.fi-connect-language::before,
.fi.fi-connect-layout::before,
.fi.fi-select::before,
.fi.fi-assign-tags::before {
  content: "\f1e0";
}
.fi.fi-users::before {
  content: "\f0c0";
}
.fi.fi-angle-right::before {
  content: "\f105";
}
.fi.fi-folder::before {
  content: "\f07b";
}
.fi.fi-user::before,
.fi.fi-user-o::before {
  content: "\f007";
}
.fi.fi-image-new::before,
.fi.fi-file-new::before {
  content: "\f005";
}
.fi.fi-move::before {
  content: "\f074";
}
.fi.fi-sign-in::before,
.fi.fi-decisionComponent-signIn::before {
  content: "\f2f6";
}
.fi.fi-arrow-circle-up::before {
  content: "\f0aa";
}
.fi.fi-window-restore::before {
  content: "\f2d2";
}
.fi.fi-plus-square::before,
.fi.fi-plus-square::before {
  content: "\f0fe";
}
.fi.fi-picture-o::before,
.fi.fi-mediaImage::before {
  content: "\f03e";
}
.fi.fi-check-circle::before {
  content: "\f058";
}
.fi.fi-participant::before {
  content: "\f508";
}
.fi.fi-arrow-circle-down::before,
.fi.fi-lazyload::before {
  content: "\f0ab";
}
.fi.fi-pause::before {
  content: "\f04c";
}
.fi.fi-arrow-circle-left::before {
  content: "\f0a8";
}
.fi.fi-media-doc::before,
.fi.fi-media-docx::before {
  content: "\f1c2";
}
.fi.fi-square-o::before {
  content: "\f0c8";
}
.fi.fi-arrow-circle-right::before {
  content: "\f0a9";
}
.fi.fi-filter::before {
  content: "\f0b0";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean::before {
  content: "\3f";
}
.fi.fi-full-screen::before,
.fi.fi-move-all::before {
  content: "\f0b2";
}
.fi.fi-leaf::before {
  content: "\f06c";
}
.fi.fi-file-excel-o::before {
  content: "\f1c3";
}
.fi.fi-move-right::before {
  content: "\f35a";
}
.fi.fi-arrow-right::before,
.fi.fi-arrow-right-stop::before {
  content: "\f061";
}
.fi.fi-circle::before,
.fi.fi-circle-o::before {
  content: "\f111";
}
.fi.fi-move-up::before {
  content: "\f35b";
}
.fi.fi-file-image-o::before,
.fi.fi-media-svg::before,
.fi.fi-media-jpg::before,
.fi.fi-media-jpeg::before,
.fi.fi-media-png::before,
.fi.fi-media-gif::before {
  content: "\f1c5";
}
.fi.fi-save::before {
  content: "\f0c7";
}
.fi.fi-sort-asc::before {
  content: "\f0de";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number::before {
  content: "\f163";
}
.fi.fi-arrow-left::before,
.fi.fi-arrow-left-stop::before {
  content: "\f060";
}
.fi.fi-file-pdf-o::before,
.fi.fi-media-pdf::before {
  content: "\f1c1";
}
.fi.fi-tooltip::before {
  content: "\f05a";
}
.fi.fi-mediaImageDetails::before,
.fi.fi-mediaFileDetails::before {
  content: "\f05a";
}
.fi.fi-list-alt::before {
  content: "\f022";
}
.fi.fi-move-left::before {
  content: "\f359";
}
.fi.fi-minus::before,
.fi.fi-minus::before {
  content: "\f068";
}
.fi.fi-gear::before,
.fi.fi-cog::before {
  content: "\f013";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time::before {
  content: "\f017";
}
.fi.fi-download::before {
  content: "\f019";
}
.fi.fi-newsletter-segment-segmentData-fieldType-text::before {
  content: "\f037";
}
.fi.fi-home::before {
  content: "\f015";
}
.fi.fi-move-down::before {
  content: "\f358";
}
.fi.fi-mediaFile::before {
  content: "\f15b";
}
.fi.fi-arrow-down::before,
.fi.fi-arrow-down-stop::before {
  content: "\f063";
}
.fi.fi-arrow-up::before,
.fi.fi-arrow-up-stop::before {
  content: "\f062";
}
.fi.fi-copy::before {
  content: "\f0c5";
}
.fi.fi-plus::before,
.fi.fi-plus::before {
  content: "\2b";
}
.fi.fi-times::before,
.fi.fi-close-full-screen::before {
  content: "\f00d";
}
.fi.fi-map-o::before {
  content: "\f279";
}
.fi.fi-refresh::before {
  content: "\f2f1";
}
.fi.fi-gears::before,
.fi.fi-cogs::before {
  content: "\f085";
}
.fi.fi-hashtag::before {
  content: "\23";
}
.fi.fi-infinity::before {
  content: "\f534";
}
.fi.fi-calendar-monthly::before {
  content: "\f133";
}
.fi.fi-plus-circle::before,
.fi.fi-plus-circle::before {
  content: "\f055";
}
.fi.fi-masterComponents::before {
  content: "\f24d";
}
.fi.fi-check::before,
.fi.fi-decisionComponent-submit::before,
.fi.fi-ok-confirm::before {
  content: "\f00c";
}
.fi.fi-angle-left::before {
  content: "\f104";
}
.fi.fi-window-close::before {
  content: "\f410";
}
.fi.fi-exclamation-triangle::before {
  content: "\f071";
}
.fi.fi-database::before {
  content: "\f1c0";
}
.fi.fi-calendar-daily::before {
  content: "\f783";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt {
  font-weight: 400;
}
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-participant {
  font-weight: 900;
}
.fi.fi-arrow-left-stop {
  border-left: 0.125em solid;
}
.fi.fi-arrow-right-stop {
  border-right: 0.125em solid;
}
.fi.fi-arrow-up-stop {
  border-top: 0.125em solid;
}
.fi.fi-arrow-down-stop {
  border-bottom: 0.125em solid;
}
.fi.fi-picture-o {
  font-weight: 400 !important;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-ExtraBold.b6022d71c34a8cd56060d79528dddc87.eot);
  src: url(../fonts/OpenSans-ExtraBold.b6022d71c34a8cd56060d79528dddc87.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(../images/OpenSans-ExtraBold.9820b5c7151893f1e4a50867a13158ae.svg#OpenSans-ExtraBold) format('svg'), url(../fonts/OpenSans-ExtraBold.021e6d47328979f2e85ec26078a7c835.ttf) format('truetype'), url(../fonts/OpenSans-ExtraBold.62a5c3ff63fbe7291102ea39985df20e.woff) format('woff'), url(../fonts/OpenSans-ExtraBold.7ea67c60e00398d6c9ad97c957cda54c.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-BoldItalic.b8bf62aaed1049ccd858ac2077fa0c11.eot);
  src: url(../fonts/OpenSans-BoldItalic.b8bf62aaed1049ccd858ac2077fa0c11.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans BoldItalic'), local('OpenSans-BoldItalic'), url(../images/OpenSans-BoldItalic.279ed18b301ff716378cfbf1dbef404d.svg#OpenSans-BoldItalic) format('svg'), url(../fonts/OpenSans-BoldItalic.c3e552d9478b218e3373364ae24c1892.ttf) format('truetype'), url(../fonts/OpenSans-BoldItalic.985182ae041b1c1c5ae010f64df42531.woff) format('woff'), url(../fonts/OpenSans-BoldItalic.d6f47b13d6e5a0de6a526fc4c6eafe4e.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-Bold.2e321d8f6df1d739322bf063176f117e.eot);
  src: url(../fonts/OpenSans-Bold.2e321d8f6df1d739322bf063176f117e.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../images/OpenSans-Bold.d827d1a5786bf3d5e2aa0902d4a943cb.svg#OpenSans-Bold) format('svg'), url(../fonts/OpenSans-Bold.8718df0c71be437824171ba01947de44.ttf) format('truetype'), url(../fonts/OpenSans-Bold.c5ade4e215340f730e565e1f3271fb72.woff) format('woff'), url(../fonts/OpenSans-Bold.b255f696353ac5efc82a492d701031a5.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-SemiBoldItalic.39b2869811175b29239cb468c9e80a23.eot);
  src: url(../fonts/OpenSans-SemiBoldItalic.39b2869811175b29239cb468c9e80a23.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans SemiBoldItalic'), local('OpenSans-SemiBoldItalic'), url(../images/OpenSans-SemiBoldItalic.351cda3cc5ad586c8f06a84a3096dfb3.svg#OpenSans-SemiBoldItalic) format('svg'), url(../fonts/OpenSans-SemiBoldItalic.7fab3bd97d0cf46a06e9e8896791eee3.ttf) format('truetype'), url(../fonts/OpenSans-SemiBoldItalic.1e713383a1fa5cb5ef6d99de9ac94461.woff) format('woff'), url(../fonts/OpenSans-SemiBoldItalic.ea3149160e4dd19a5ce00a01f2b970aa.woff2) format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-ExtraBoldItalic.a6bfd3dd919ae3057e04ad81d6afbb4c.eot);
  src: url(../fonts/OpenSans-ExtraBoldItalic.a6bfd3dd919ae3057e04ad81d6afbb4c.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans ExtraBoldItalic'), local('OpenSans-ExtraBoldItalic'), url(../images/OpenSans-ExtraBoldItalic.836af498e2ac0a6270092bb1c1aafb91.svg#OpenSans-ExtraBoldItalic) format('svg'), url(../fonts/OpenSans-ExtraBoldItalic.93c92905121822d57ad491b53a988bd1.ttf) format('truetype'), url(../fonts/OpenSans-ExtraBoldItalic.964e06dd91d5cded4dba68f2b522eb30.woff) format('woff'), url(../fonts/OpenSans-ExtraBoldItalic.ff28a48ed7d458d4b64ce62e1a94a2af.woff2) format('woff2');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-Italic.9609e51ab181db0dc02aa09e4c6be685.eot);
  src: url(../fonts/OpenSans-Italic.9609e51ab181db0dc02aa09e4c6be685.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../images/OpenSans-Italic.622eacd48169cd1c204bd4f14f62dc21.svg#OpenSans-Italic) format('svg'), url(../fonts/OpenSans-Italic.1d74a983a71c3a501dbb7b657695e91c.ttf) format('truetype'), url(../fonts/OpenSans-Italic.2418a91332ae93f64389b7986dd9affb.woff) format('woff'), url(../fonts/OpenSans-Italic.323505a3f81251647e79834aaf6dd1cf.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-Light.96a7330db529bc8b9c1c7a04e1bf3624.eot);
  src: url(../fonts/OpenSans-Light.96a7330db529bc8b9c1c7a04e1bf3624.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../images/OpenSans-Light.d669cfdf38a5f3a94fda156b4576f2a4.svg#OpenSans-Light) format('svg'), url(../fonts/OpenSans-Light.40d9cdff9f69ff77442b1a3a17ec8b68.ttf) format('truetype'), url(../fonts/OpenSans-Light.ed9151aa4fca51d9ae341b8bbbd86c30.woff) format('woff'), url(../fonts/OpenSans-Light.104cd790ddec1eda7c37576b34399043.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-LightItalic.0b6ab6e6cb6d5a208229a2f2c438c9ef.eot);
  src: url(../fonts/OpenSans-LightItalic.0b6ab6e6cb6d5a208229a2f2c438c9ef.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans LightItalic'), local('OpenSans-LightItalic'), url(../images/OpenSans-LightItalic.84aedd80094af1369f0aa8ad93a05a21.svg#OpenSans-LightItalic) format('svg'), url(../fonts/OpenSans-LightItalic.a8603d41cf31b86545aa10fdba9bf8c3.ttf) format('truetype'), url(../fonts/OpenSans-LightItalic.64b4192a694316b0b81762fb55d01965.woff) format('woff'), url(../fonts/OpenSans-LightItalic.369cc627562690fd0e0a0823aa4e5974.woff2) format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-Regular.4d3f5460e19a00441eb5609093e5c6eb.eot);
  src: url(../fonts/OpenSans-Regular.4d3f5460e19a00441eb5609093e5c6eb.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../images/OpenSans-Regular.35241749d29a69566996a1001b7ee08d.svg#OpenSans-Regular) format('svg'), url(../fonts/OpenSans-Regular.4b49af5be48b579a95bdf0ed281a5962.ttf) format('truetype'), url(../fonts/OpenSans-Regular.7b297dc61c31074c850226f8ef5c9542.woff) format('woff'), url(../fonts/OpenSans-Regular.94b67da022588878ec1f9b285a0182cf.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url(../fonts/OpenSans-SemiBold.85d32b86ae178c4ce43f6a60712c48f4.eot);
  src: url(../fonts/OpenSans-SemiBold.85d32b86ae178c4ce43f6a60712c48f4.eot?#iefix) format('embedded-opentype');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(../images/OpenSans-SemiBold.69822764bc598cf094c78f0c8e831530.svg#OpenSans-SemiBold) format('svg'), url(../fonts/OpenSans-SemiBold.b608df5816de418ac09b87d8dc2d3b44.ttf) format('truetype'), url(../fonts/OpenSans-SemiBold.779f859bed4bd22242be86e2237a9106.woff) format('woff'), url(../fonts/OpenSans-SemiBold.ec1f09b9bbed29ebc499a21f695cbdab.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-participant {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: inline-block;font-style: normal;font-variant: normal;text-rendering: auto;line-height: 1;
}
.fi.fa-pull-left,
.fi.fi-square-o.fa-pull-left,
.fi.fi-map-o.fa-pull-left,
.fi.fi-trash.fa-pull-left,
.fi.fi-circle-o.fa-pull-left,
.fi.fi-gear.fa-pull-left,
.fi.fi-cog.fa-pull-left,
.fi.fi-gears.fa-pull-left,
.fi.fi-cogs.fa-pull-left,
.fi.fi-sign-in.fa-pull-left,
.fi.fi-sign-out.fa-pull-left,
.fi.fi-check.fa-pull-left,
.fi.fi-copy.fa-pull-left,
.fi.fi-save.fa-pull-left,
.fi.fi-error.fa-pull-left,
.fi.fi-arrow-left-stop.fa-pull-left,
.fi.fi-arrow-right-stop.fa-pull-left,
.fi.fi-arrow-up-stop.fa-pull-left,
.fi.fi-arrow-down-stop.fa-pull-left,
.fi.fi-calendar-daily.fa-pull-left,
.fi.fi-calendar-monthly.fa-pull-left,
.fi.fi-tooltip.fa-pull-left,
.fi.fi-file-o.fa-pull-left,
.fi.fi-file-excel-o.fa-pull-left,
.fi.fi-file-text-o.fa-pull-left,
.fi.fi-file-pdf-o.fa-pull-left,
.fi.fi-picture-o.fa-pull-left,
.fi.fi-user-o.fa-pull-left,
.fi.fi-mediaImage.fa-pull-left,
.fi.fi-mediaFile.fa-pull-left,
.fi.fi-media-pdf.fa-pull-left,
.fi.fi-media-svg.fa-pull-left,
.fi.fi-media-jpg.fa-pull-left,
.fi.fi-media-jpeg.fa-pull-left,
.fi.fi-media-png.fa-pull-left,
.fi.fi-media-gif.fa-pull-left,
.fi.fi-media-doc.fa-pull-left,
.fi.fi-media-docx.fa-pull-left,
.fi.fi-media-txt.fa-pull-left,
.fi.fi-participant.fa-pull-left {
  margin-right: 0.3em;
}
.fi.fa-pull-right,
.fi.fi-square-o.fa-pull-right,
.fi.fi-map-o.fa-pull-right,
.fi.fi-trash.fa-pull-right,
.fi.fi-circle-o.fa-pull-right,
.fi.fi-gear.fa-pull-right,
.fi.fi-cog.fa-pull-right,
.fi.fi-gears.fa-pull-right,
.fi.fi-cogs.fa-pull-right,
.fi.fi-sign-in.fa-pull-right,
.fi.fi-sign-out.fa-pull-right,
.fi.fi-check.fa-pull-right,
.fi.fi-copy.fa-pull-right,
.fi.fi-save.fa-pull-right,
.fi.fi-error.fa-pull-right,
.fi.fi-arrow-left-stop.fa-pull-right,
.fi.fi-arrow-right-stop.fa-pull-right,
.fi.fi-arrow-up-stop.fa-pull-right,
.fi.fi-arrow-down-stop.fa-pull-right,
.fi.fi-calendar-daily.fa-pull-right,
.fi.fi-calendar-monthly.fa-pull-right,
.fi.fi-tooltip.fa-pull-right,
.fi.fi-file-o.fa-pull-right,
.fi.fi-file-excel-o.fa-pull-right,
.fi.fi-file-text-o.fa-pull-right,
.fi.fi-file-pdf-o.fa-pull-right,
.fi.fi-picture-o.fa-pull-right,
.fi.fi-user-o.fa-pull-right,
.fi.fi-mediaImage.fa-pull-right,
.fi.fi-mediaFile.fa-pull-right,
.fi.fi-media-pdf.fa-pull-right,
.fi.fi-media-svg.fa-pull-right,
.fi.fi-media-jpg.fa-pull-right,
.fi.fi-media-jpeg.fa-pull-right,
.fi.fi-media-png.fa-pull-right,
.fi.fi-media-gif.fa-pull-right,
.fi.fi-media-doc.fa-pull-right,
.fi.fi-media-docx.fa-pull-right,
.fi.fi-media-txt.fa-pull-right,
.fi.fi-participant.fa-pull-right {
  margin-left: 0.3em;
}
.fi.fi-newsletter-segment-segmentData-fieldType-text:before {
  content: "\f037";
}
.fi.fi-angle-left:before {
  content: "\f104";
}
.fi.fi-angle-right:before {
  content: "\f105";
}
.fi.fi-move-down:before {
  content: "\f358";
}
.fi.fi-move-left:before {
  content: "\f359";
}
.fi.fi-move-right:before {
  content: "\f35a";
}
.fi.fi-move-up:before {
  content: "\f35b";
}
.fi.fi-arrow-circle-down:before,
.fi.fi-lazyload:before {
  content: "\f0ab";
}
.fi.fi-arrow-circle-left:before {
  content: "\f0a8";
}
.fi.fi-arrow-circle-right:before {
  content: "\f0a9";
}
.fi.fi-arrow-circle-up:before {
  content: "\f0aa";
}
.fi.fi-arrow-down:before,
.fi.fi-arrow-down-stop:before {
  content: "\f063";
}
.fi.fi-arrow-left:before,
.fi.fi-arrow-left-stop:before {
  content: "\f060";
}
.fi.fi-arrow-right:before,
.fi.fi-arrow-right-stop:before {
  content: "\f061";
}
.fi.fi-arrow-up:before,
.fi.fi-arrow-up-stop:before {
  content: "\f062";
}
.fi.fi-full-screen:before,
.fi.fi-move-all:before {
  content: "\f0b2";
}
.fi.fi-calendar-monthly:before {
  content: "\f133";
}
.fi.fi-calendar:before,
.fi.fi-newsletter-segment-segmentData-fieldType-date:before {
  content: "\f073";
}
.fi.fi-check:before,
.fi.fi-decisionComponent-submit:before,
.fi.fi-ok-confirm:before {
  content: "\f00c";
}
.fi.fi-check-circle:before {
  content: "\f058";
}
.fi.fi-circle:before,
.fi.fi-circle-o:before {
  content: "\f111";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time:before {
  content: "\f017";
}
.fi.fi-masterComponents:before {
  content: "\f24d";
}
.fi.fi-gear:before,
.fi.fi-cog:before {
  content: "\f013";
}
.fi.fi-gears:before,
.fi.fi-cogs:before {
  content: "\f085";
}
.fi.fi-copy:before {
  content: "\f0c5";
}
.fi.fi-database:before {
  content: "\f1c0";
}
.fi.fi-download:before {
  content: "\f019";
}
.fi.fi-edit:before {
  content: "\f044";
}
.fi.fi-exclamation-circle:before,
.fi.fi-error:before {
  content: "\f06a";
}
.fi.fi-exclamation-triangle:before {
  content: "\f071";
}
.fi.fi-mediaFile:before {
  content: "\f15b";
}
.fi.fi-file:before,
.fi.fi-file-o:before,
.fi.fi-file-text-o:before,
.fi.fi-media-txt:before {
  content: "\f15c";
}
.fi.fi-file-excel-o:before {
  content: "\f1c3";
}
.fi.fi-file-image-o:before,
.fi.fi-media-svg:before,
.fi.fi-media-jpg:before,
.fi.fi-media-jpeg:before,
.fi.fi-media-png:before,
.fi.fi-media-gif:before {
  content: "\f1c5";
}
.fi.fi-file-pdf-o:before,
.fi.fi-media-pdf:before {
  content: "\f1c1";
}
.fi.fi-media-doc:before,
.fi.fi-media-docx:before {
  content: "\f1c2";
}
.fi.fi-filter:before {
  content: "\f0b0";
}
.fi.fi-folder:before {
  content: "\f07b";
}
.fi.fi-hashtag:before {
  content: "\f292";
}
.fi.fi-home:before {
  content: "\f015";
}
.fi.fi-picture-o:before,
.fi.fi-mediaImage:before {
  content: "\f03e";
}
.fi.fi-infinity:before {
  content: "\f534";
}
.fi.fi-mediaImageDetails:before,
.fi.fi-mediaFileDetails:before {
  content: "\f05a";
}
.fi.fi-leaf:before {
  content: "\f06c";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list:before {
  content: "\f03a";
}
.fi.fi-list-alt:before {
  content: "\f022";
}
.fi.fi-lock:before {
  content: "\f023";
}
.fi.fi-map-o:before {
  content: "\f279";
}
.fi.fi-minus:before,
.fi.fi-minus:before {
  content: "\f068";
}
.fi.fi-minus-circle:before {
  content: "\f056";
}
.fi.fi-minus-square:before,
.fi.fi-minus-square:before {
  content: "\f146";
}
.fi.fi-pause:before {
  content: "\f04c";
}
.fi.fi-edit-slim:before {
  content: "\f303";
}
.fi.fi-plus:before,
.fi.fi-plus:before {
  content: "\f067";
}
.fi.fi-plus-circle:before,
.fi.fi-plus-circle:before {
  content: "\f055";
}
.fi.fi-plus-square:before,
.fi.fi-plus-square:before {
  content: "\f0fe";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean:before {
  content: "\f128";
}
.fi.fi-move:before {
  content: "\f074";
}
.fi.fi-save:before {
  content: "\f0c7";
}
.fi.fi-share-alt:before,
.fi.fi-assign:before,
.fi.fi-connect-language:before,
.fi.fi-connect-layout:before,
.fi.fi-select:before,
.fi.fi-assign-tags:before {
  content: "\f1e0";
}
.fi.fi-sign-in:before,
.fi.fi-decisionComponent-signIn:before {
  content: "\f2f6";
}
.fi.fi-sign-out:before {
  content: "\f2f5";
}
.fi.fi-sort-desc:before {
  content: "\f0dd";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number:before {
  content: "\f163";
}
.fi.fi-sort-asc:before {
  content: "\f0de";
}
.fi.fi-square-o:before {
  content: "\f0c8";
}
.fi.fi-image-new:before,
.fi.fi-file-new:before {
  content: "\f005";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-refresh:before {
  content: "\f2f1";
}
.fi.fi-times:before,
.fi.fi-close-full-screen:before {
  content: "\f00d";
}
.fi.fi-trash:before {
  content: "\f2ed";
}
.fi.fi-user:before,
.fi.fi-user-o:before {
  content: "\f007";
}
.fi.fi-participant:before {
  content: "\f508";
}
.fi.fi-users:before {
  content: "\f0c0";
}
.fi.fi-window-close:before {
  content: "\f410";
}
.fi.fi-window-restore:before {
  content: "\f2d2";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt {
  font-family: 'Font Awesome 5 Free';font-weight: 400;
}
.fi,
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-participant {
  font-family: 'Font Awesome 5 Free';font-weight: 900;
}
